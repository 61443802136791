import React, { FC } from "react";
import Image from "next/legacy/image";
import { Form, Input } from "antd";
import { Button } from "@components/atoms/Button";
import EnvelopeIcon from "@public/svg/message-small.svg";
import styles from "./index.module.scss";

export type AddReferralFormOutput = {
  company: string;
  comment: string;
};

export type Props = {
  onAddReferral: (values: AddReferralFormOutput) => void;
  loading: boolean;
  onClose: () => void;
};

const { TextArea: AntDTextArea } = Input;

export const AddReferralManually: FC<Props> = ({
  onAddReferral,
  onClose,
  loading,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.close} onClick={onClose}>
        <Image
          src={"/svg/close.svg"}
          alt={"close image"}
          height={18}
          width={18}
        />
      </div>
      <div className={styles.envelopeWrapper}>
        <EnvelopeIcon className={styles.envelopeIcon} />
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.descWrapper}>
          <span className={styles.cardDesc}>Pievieno uzņēmuma informāciju</span>
        </div>

        <Form
          layout="vertical"
          style={{ paddingTop: "15px" }}
          wrapperCol={{ span: 24 }}
          onFinish={onAddReferral}
        >
          <Form.Item
            name="company"
            rules={[
              {
                required: true,
                type: "string",
                message: "Ievadi uzņēmuma nosaukumu!",
              },
            ]}
          >
            <Input
              spellCheck={false}
              style={{
                opacity: "1",
                color: "rgba(255, 255, 255, 0.7)",
                height: "55px",
                borderRadius: "10px",
                paddingLeft: "12px",
              }}
              placeholder="Uzņēmuma nosaukums"
              disabled={false}
            />
          </Form.Item>
          <Form.Item
            name="comment"
            rules={[
              {
                required: true,
                type: "string",
                message: "Ievadi komentāru!",
              },
            ]}
          >
            <AntDTextArea
              rows={4}
              spellCheck={false}
              style={{
                opacity: "1",
                color: "rgba(255, 255, 255, 0.7)",
                height: "141px",
                borderRadius: "10px",
                paddingLeft: "12px",
                paddingTop: "16px",
              }}
              placeholder="Komentārs"
              disabled={false}
            />
          </Form.Item>

          <Form.Item>
            <div className={styles.buttonWrapper}>
              <Button type="default" htmlType="submit" disabled={loading}>
                Apstiprināt
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
