import React, { FC } from "react";
import { useRouter } from "next/router";
import Icon from "@public/svg/danger.svg";
import { useHydrationSafeDateAsNumber } from "@components/hooks/use-hydration-safe-date";
import styles from "./index.module.scss";

type Props = {
  jobStatus: string;
};

export const JobStatusBanner: FC<Props> = ({ jobStatus }) => {
  const router = useRouter();
  const daysAfterLastJobStatusFormSubmittion = Math.round(
    (useHydrationSafeDateAsNumber(new Date()) -
      useHydrationSafeDateAsNumber(jobStatus)) /
      (1000 * 60 * 60 * 24),
  );

  return (
    <div
      onClick={() => router.push("/employment-check")}
      className={styles.container}
    >
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>Darba statuss</h1>
        </div>
        {daysAfterLastJobStatusFormSubmittion > 90 && (
          <span className={styles.icon}>
            <Icon />
          </span>
        )}
        <div className={styles.textWrapper}>
          <p className={styles.text}>
            Mums ir svarīgi, lai Tu justos apmierināts ar savu darba vietu,
            tāpēc aicinām reizi trīs mēnešos aizpildīt darba statusa formu.
          </p>
        </div>
      </div>
    </div>
  );
};
