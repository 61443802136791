import React, { FC } from "react";
import classnames from "classnames";
import Image from "next/legacy/image";
import { ReferralStatistics } from "@domain/referrals/csr";
import { ReferralType } from "@domain/referrals/types";
import { formatDateNative } from "@utils/date-time/common";
import styles from "./index.module.scss";

export type Props = {
  stats: Omit<ReferralStatistics, "__typename">;
  onAddComment: (id: string) => void;
  onClickComments: (id: string) => void;
};

export const ReferralStatisticCards: FC<Props> = ({
  stats,
  onAddComment,
  onClickComments,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filterWrapper}>
        <div className={styles.filterDate}>Kārtot pēc datuma</div>
        <div className={styles.filterAccpeted}>Rādīt ieplānotās</div>
      </div>
      <div className={styles.cardsWrapper}>
        {stats.entries.map(
          ({ date, meetingBooked, company, type, id, comments }) => {
            const hasComments = comments.length > 0;
            return (
              <div key={id} className={styles.cardWrapper}>
                <div
                  className={classnames(styles.upperPart, {
                    [styles.cardBlack]:
                      !meetingBooked && type === ReferralType.REDIRECT,
                    [styles.cardBlue]:
                      !meetingBooked && type === ReferralType.MANUAL,
                    [styles.cardGreen]: meetingBooked,
                  })}
                >
                  <div className={styles.date}>
                    <div className={styles.label}>Datums & Laiks</div>
                    <div className={styles.desc} suppressHydrationWarning>
                      {formatDateNative(new Date(date))}
                    </div>
                  </div>

                  <div className={styles.status}>
                    <div className={styles.label}>Tikšanās ieplānota</div>
                    <div className={styles.desc}>
                      {meetingBooked ? "Jā" : "Nē"}
                    </div>
                  </div>

                  <div className={styles.company}>
                    <div className={styles.label}>Uzņēmuma nosaukums</div>
                    <div className={styles.desc}>{company ?? "-"}</div>
                  </div>
                </div>

                <div
                  className={classnames(styles.downPart, {
                    [styles.cardBlackish]:
                      !meetingBooked && type === ReferralType.REDIRECT,
                    [styles.cardBlueish]:
                      !meetingBooked && type === ReferralType.MANUAL,
                    [styles.cardGreenish]: meetingBooked,
                  })}
                >
                  <div className={styles.socialWrapper}>
                    <div
                      onClick={() => {
                        if (hasComments) {
                          onClickComments(id);
                        }
                      }}
                      className={classnames(styles.notification, {
                        [styles.pointer]: hasComments,
                      })}
                    >
                      <Image
                        src={"/svg/notification.svg"}
                        alt={"notification"}
                        width={34}
                        height={27}
                      />
                      <div className={styles.notificationMessageCount}>
                        <div className={styles.countNotifications}>
                          {comments?.length}
                        </div>
                      </div>
                    </div>
                  </div>

                  <button
                    className={classnames(styles.addComment, {
                      [styles.addCommentBlackCard]:
                        !meetingBooked && type === ReferralType.REDIRECT,
                    })}
                    onClick={() => onAddComment(id)}
                  >
                    PIEVIENOT KOMENTĀRU
                  </button>
                </div>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
};
