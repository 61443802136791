import React from "react";
import classNames from "classnames";
import { ReferralContentCard } from "@components/atoms/ReferralContentCard";
import { Language } from "@domain/types";
import { MessageTemplate } from "@domain/referrals/types";
import { copyToClipboard } from "@integration/clipboard";
import styles from "./index.module.scss";

type Props = {
  language: Language;
  onChangeLanguage: (lang: Language) => void;
  templates: MessageTemplate[];
};

export const ReferralContentComponent = ({
  language,
  onChangeLanguage,
  templates,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapperTitle}>
        <h1 className={styles.title}>Sagataves</h1>
        <h3 className={styles.subTitle}>
          Iepazīstini ar Codelex, izvēloties kādu no mūsu sagatavotajiem atbilžu
          variantiem un sniedz atbildi uz izrādīto interesi.
        </h3>
      </div>
      <div className={styles.wrapperSelectButtons}>
        {[Language.LATVIAN, Language.ENGLISH].map((lang) => (
          <button
            id={lang}
            key={lang}
            onClick={() => onChangeLanguage(lang)}
            className={classNames(styles.button, {
              [styles.buttonSelected]: language === lang,
            })}
          >
            {lang === Language.LATVIAN ? "LV" : "EN"}
          </button>
        ))}
      </div>
      <div className={styles.cardsWrapper}>
        {templates.map(({ content, copyText }, i) => {
          return (
            <div key={i}>
              <ReferralContentCard
                card={content}
                copyText={copyText}
                copyToClipboard={() => copyToClipboard({ content })}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
