import { Table, Tag } from "antd";
import classnames from "classnames";
import {
  Button,
  MultiStageNotificationBanner,
  StudentInvoiceCard,
} from "@components/atoms";
import { type ScheduleItem } from "@domain/schedules/ssr/fetch-user-schedule-items";
import styles from "./index.module.scss";

type Props = {
  paymentScheduleItems: ScheduleItem[];
};

export const PaymentSchedule = ({ paymentScheduleItems }: Props) => {
  const delayedInvoicesCount = paymentScheduleItems.filter(
    (invoice) => invoice.status === "delayed",
  ).length;
  const firstDelayedInvoice = paymentScheduleItems
    .slice()
    .reverse()
    .find((invoice) => invoice.status === "delayed");
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Rēķini</h1>
      {delayedInvoicesCount > 0 && (
        <div className={styles.notificationBannerWrapper}>
          <MultiStageNotificationBanner
            content={`Tev nav ${
              delayedInvoicesCount === 1
                ? `apmaksāts 1 rēķins. Lūdzu, apmaksā rēķinu nr. ${firstDelayedInvoice?.invoiceNumber}`
                : `apmaksāti ${delayedInvoicesCount} rēķini. Lūdzu, apskati, kuri maksājumi grafikā ir atzīmēti ar statusu "Nokavēts". Veic apmaksu sākot ar rēķinu nr. ${firstDelayedInvoice?.invoiceNumber}.`
            }`}
            type="danger"
            title="Pievērs uzmanību!"
          />{" "}
        </div>
      )}
      <div className={styles.tableWrapper}>
        <Table
          rowKey={"invoiceNumber"}
          scroll={{ x: 780 }}
          className="client-table"
          columns={[
            {
              title: "Rēķina numurs",
              dataIndex: "invoiceNumber",
              key: "invoiceNumber",
              align: "center",
            },
            {
              title: "Rēķina datums",
              dataIndex: "invoiceDate",
              key: "invoiceDate",
              align: "center",
            },
            {
              title: "Summa",
              dataIndex: "amount",
              key: "amount",
              align: "center",
              render: (text) => `${text} €`,
            },
            {
              title: "Statuss",
              dataIndex: "status",
              key: "status",
              align: "center",
              width: "120px",
              render: (_, { status }, index) =>
                status === "closed" ? (
                  <Tag className={styles.greenTag} key={index}>
                    Samaksāts
                  </Tag>
                ) : status === "delayed" ? (
                  <Tag className={styles.redTag} key={index}>
                    Nokavēts
                  </Tag>
                ) : (
                  <Tag className={styles.greyTag} key={index}>
                    Atvērts
                  </Tag>
                ),
            },
            {
              title: "",
              key: "action",
              width: "165px",
              render: (_, { invoiceNumber, accessKey, wherefore }) => (
                <div
                  className={classnames(styles.buttonWrapper, {
                    [styles.activeButtonWrapper]:
                      invoiceNumber && accessKey && wherefore,
                  })}
                >
                  {invoiceNumber && accessKey && wherefore && (
                    <Button
                      disabled={!(invoiceNumber && accessKey && wherefore)}
                      onClick={() => {
                        window.open(
                          `/invoices/${invoiceNumber}?access-key=${accessKey}`,
                          "_blank",
                        );
                      }}
                      type="ghost"
                    >
                      Atvērt rēķinu
                    </Button>
                  )}
                </div>
              ),
            },
          ]}
          dataSource={paymentScheduleItems}
          pagination={false}
        />
      </div>
      <div className={styles.cardsWrapper}>
        {paymentScheduleItems.map((item, index) => (
          <StudentInvoiceCard key={index} invoiceDetails={item} />
        ))}
      </div>
    </div>
  );
};
