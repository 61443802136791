import Link from "next/link";
import classNames from "classnames";
import React from "react";
import { HeaderLogo } from "@components/atoms";
import { MenuEntry } from "@domain/admin/menu/types";
import styles from "./index.module.scss";

export interface Props {
  currentPath: string;
  menuItems: MenuEntry[];
}

export const AdminNavMenuVertical = ({ currentPath, menuItems }: Props) => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.headerWrapper}>
          <HeaderLogo link="/" />
        </div>
        {menuItems.map((item, i) => {
          const isExactMatch = currentPath === item.path;
          const isChildActive = item.children?.some(
            (child) => currentPath === child.path,
          );

          const isParentActive =
            item.children && (isExactMatch || isChildActive);

          return (
            <div
              key={i}
              className={classNames(styles.link, {
                [styles.linkWithSublinks]: isParentActive,
                [styles.activelink]: isExactMatch && !isChildActive,
                [styles.mainLinkActive]: isChildActive,
              })}
            >
              <div>
                <Link href={item.path}>{item.title}</Link>
              </div>
              {isParentActive && item.children && (
                <>
                  {item.children.map((subItem, index) => (
                    <div
                      key={index}
                      className={classNames(styles.subLinks, {
                        [styles.subLinkActive]: currentPath === subItem.path,
                      })}
                    >
                      <Link href={subItem.path}>{subItem.title}</Link>
                    </div>
                  ))}
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
