import { FC } from "react";
import { Divider } from "antd";
import {
  differenceInDays,
  differenceHoursOnly,
  differenceMinutesOnly,
} from "@utils/date-time/common";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

export interface Props {
  now: Date | number;
  startTime: Date | number;
  onJoin?: () => void;
}

export const NextLessonTimer: FC<Props> = ({ now, startTime, onJoin }) => {
  const time = {
    days: differenceInDays(startTime, now),
    hours: differenceHoursOnly(startTime, now),
    minutes: differenceMinutesOnly(startTime, now),
  };
  return (
    <>
      {onJoin ? (
        <div className={styles.wrapper}>
          <div className={styles.labelWrapper}>
            <p className={styles.label}>Notiek nodarbība</p>
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              onClick={onJoin}
              style={{ minWidth: "fit-content" }}
              data-testid="join-intro-lesson-btn"
            >
              Pievienoties
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.labelWrapper}>
            <p className={styles.label}>Nodarbība sākas pēc:</p>
          </div>
          <div className={styles.countdown}>
            <div className={styles.time}>
              <span className={styles.num}>
                {time.days ? time.days.toString().padStart(2, "0") : "00"}
              </span>
              <span className={styles.dateText}>dienām</span>
            </div>
            <Divider type="vertical" className={styles.divider} />
            <div className={styles.time}>
              <span className={styles.num}>
                {time.hours ? time.hours.toString().padStart(2, "0") : "00"}
              </span>
              <span className={styles.dateText}>stundām</span>
            </div>
            <span className={styles.separator}>:</span>
            <div className={styles.time}>
              <span className={styles.num}>
                <span
                  className={styles.num}
                  data-testid="intro-course-countdown"
                >
                  {time.minutes
                    ? time.minutes.toString().padStart(2, "0")
                    : "00"}
                </span>
              </span>
              <span className={styles.dateText}>minūtēm</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
