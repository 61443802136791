import React, { useEffect } from "react";
import classNames from "classnames";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import NextNavMenu from "@components/atoms/NextNavMenuVertical/NextNavMenu";
import { Step } from "@domain/assignment-games/types";
import styles from "./index.module.scss";

export type Props = {
  stepper?: {
    key: Step;
    active: boolean;
    completed: boolean;
  }[];
  open: boolean;
  burgerRef: React.MutableRefObject<HTMLDivElement>;
  emailVerified: boolean;
};

export const BurgerNextMenuNav: React.FC<Props> = ({
  stepper,
  open,
  burgerRef,
  emailVerified,
}) => {
  useEffect(() => {
    open ? disableBodyScroll(burgerRef.current) : clearAllBodyScrollLocks();
  }, [open, burgerRef]);

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.open]: open,
        [styles.removeSpace]: emailVerified,
      })}
    >
      {stepper &&
        stepper.map((item, i) => {
          return (
            <NextNavMenu
              item={item}
              i={i}
              objectLength={stepper.length}
              key={i}
            />
          );
        })}
    </div>
  );
};
