import React from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

type Props = {
  title: string;
  imageUrl?: string;
  text: string;
};

export const SupportPaymentBanner = ({ title, imageUrl, text }: Props) => {
  return (
    <div
      className={classnames(styles.container, {
        [styles.containerWithImage]: imageUrl,
      })}
      style={{ backgroundImage: `url("${imageUrl}")` }}
    >
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <h1
            className={classnames(styles.title, {
              [styles.titleWithImage]: imageUrl,
            })}
          >
            {title}
          </h1>
        </div>
        <div className={styles.textWrapper}>
          <p className={styles.text}>
            <b>{text}</b>
          </p>
        </div>
      </div>
    </div>
  );
};
