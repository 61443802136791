import React, { FC } from "react";
import { LiteralUnion } from "next-auth/react";
import { BuiltInProviderType } from "next-auth/providers";
import { Button } from "@components/atoms/Button";
import { NextAuthButton } from "@components/atoms/NextAuthButton";
import styles from "./index.module.scss";

type Props = {
  email: string;
  redirectToSignIn: () => void;
  provider: LiteralUnion<BuiltInProviderType, string>;
  onAuth: () => void;
};

export const SignInWithSocials: FC<Props> = ({
  email,
  redirectToSignIn,
  onAuth,
  provider,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headWrapper}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>Pierakstīties vietnē Codelex</h1>
        </div>
        <div className={styles.descWrapper}>
          <span className={styles.desc} data-testid="auth-details">
            Izskatās, ka e-pasta adrese{" "}
            <span className={styles.highLight}>{email}</span> jau ir reģistrēta
            un savienota ar <span className={styles.highLight}>{provider}</span>{" "}
            kontu.
          </span>
        </div>

        <div
          className={styles.authWrapper}
          data-testid="external-provider-auth-btn"
        >
          <NextAuthButton
            previousAuthMethod={null}
            provider={provider}
            onClick={onAuth}
          />
        </div>
      </div>

      <div className={styles.cardWrapper}>
        <div className={styles.descWrapperCard}>
          <span className={styles.desc}>
            Nevēlies piekļūt Codelex vietnei? Spied uz pogas un lieto citu
            autentifikācijas metodi.
          </span>
        </div>

        <div className={styles.repeatButtonWrapper}>
          <Button
            onClick={redirectToSignIn}
            style={{ fontSize: "14px" }}
            type="ghost"
            htmlType="submit"
          >
            Autorizēties ar citu metodi
          </Button>
        </div>
      </div>
    </div>
  );
};
