import Image from "next/legacy/image";
import { LockUi } from "@components/atoms/LockUi";
import {
  toStatus,
  AccountExternalConnection,
  ExternalConnectionType,
} from "@domain/account/external-connections";
import { capitalize } from "@utils/capitalize";
import styles from "./index.module.scss";

const implemented: ExternalConnectionType[] = ["discord"];

export interface Props {
  externalConnections: AccountExternalConnection[];
  onClick: (connection: AccountExternalConnection) => void;
}

export const ExternalConnectionsCard = ({
  externalConnections,
  onClick,
}: Props) => {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Ārējie konti</h3>

      <div className={styles.linkContainer}>
        <div className={styles.linkWrapper}>
          {externalConnections
            .filter((it) => implemented.includes(it.name))
            .map((it) => {
              return (
                <div key={it.name} className={styles.spaceBetween}>
                  <div className={styles.iconWrapper}>
                    <Image
                      src={`/svg/${it.name}.svg`}
                      alt={it.name}
                      height={30}
                      width={30}
                    />
                    <div className={styles.desc}>
                      Pievienoties <br />
                      <span className={styles.highLight}>
                        {capitalize(it.name)}
                      </span>
                    </div>
                  </div>
                  <LockUi
                    forbidden={true}
                    onClick={() => onClick(it)}
                    lockStatus={toStatus({ connection: it })}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
