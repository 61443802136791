import React from "react";
import classnames from "classnames";
import { useRouter } from "next/router";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

type Props = {
  isPaymentScheduleAvailable: boolean;
};

export const PaymentInfoBanner = ({ isPaymentScheduleAvailable }: Props) => {
  const router = useRouter();
  return (
    <div
      className={classnames(styles.container, {
        [styles.adjustedContainer]: isPaymentScheduleAvailable,
      })}
    >
      <h1 className={styles.title}>
        Informācija par{" "}
        {isPaymentScheduleAvailable ? "rēķiniem" : "maksājumu veikšanu"}
      </h1>
      <div
        className={classnames(styles.textWrapper, {
          [styles.wrapper]: isPaymentScheduleAvailable,
        })}
      >
        {!isPaymentScheduleAvailable ? (
          <>
            <p className={styles.text}>
              {" "}
              Apsveicam ar darba piedāvājuma saņemšanu! Šajā sadaļā atradīsi
              visu, kas saistīts ar turpmākajiem maksājumiem.
            </p>
            <p className={styles.text}>
              ❗️Maksājumus var atlikt uz laiku ne ilgāku par sešiem mēnešiem.
            </p>
            <p className={styles.text}>
              ❗️Periodiski varam veikt ienākumu pārbaudi uz pilnvaras pamata.
            </p>
          </>
        ) : (
          <div className={styles.adjustedTextWrapper}>
            <p className={styles.text}>
              {" "}
              Maksājumus var atlikt uz laiku ne ilgāku par sešiem mēnešiem.
            </p>
            <p className={styles.text}>
              Informējam, ka periodiski varam veikt ienākumu pārbaudi uz
              pilnvaras pamata.
            </p>
          </div>
        )}
        {isPaymentScheduleAvailable && (
          <div className={styles.buttonWrapper}>
            <Button
              onClick={() => {
                router.push("/payments/payment-schedule");
              }}
              type="ghost"
            >
              Maksājumu grafiks
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
