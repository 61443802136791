import React from "react";
import { Button } from "src/components/atoms/Button";
import styles from "./index.module.scss";

export interface Props {
  onApply: () => void;
}

export const ApplicationBanner: React.FC<Props> = ({ onApply }) => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Pieteikuma forma</h1>
      <h4 className={styles.subTitle}>
        Aizpildi šo formu, lai pieteiktos intervijai.
      </h4>
      <div className={styles.btnWrapper}>
        <Button onClick={onApply}>Pieteikties</Button>
      </div>
      <p className={styles.description}>
        Pēc pieteikuma anketas aizpildīšanas visa komunikācija notiks
        e&ndash;pastā. Lai nepalaistu garām ko svarīgu, vēlams ik pa laikam
        pārbaudīt arī promotions un spam mapes.
      </p>
    </div>
  );
};
