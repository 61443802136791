import React, { FC } from "react";
import { Form, DatePicker } from "antd";
import { Button } from "@components/atoms/Button";
import { AcceptJobOfferInput } from "@domain/job-offers/types";

export type Props = {
  jobOfferId: string;
  onAcceptJobOffer: (values: AcceptJobOfferInput) => void;
  loading: boolean;
};

const config = {
  rules: [
    {
      type: "object" as const,
      required: true,
      message: "Ievadi datumu!",
    },
  ],
};

export const AcceptJobOfferForm: FC<Props> = ({
  jobOfferId,
  onAcceptJobOffer,
  loading,
}) => {
  return (
    <Form
      name="acceptOffer"
      layout="vertical"
      onFinish={(values) =>
        onAcceptJobOffer({
          id: jobOfferId,
          employmentStartDate: values.employmentStartDate,
        })
      }
    >
      <Form.Item name="employmentStartDate" {...config}>
        <DatePicker />
      </Form.Item>
      <Button disabled={loading} htmlType="submit">
        Apstiprināt
      </Button>
    </Form>
  );
};
