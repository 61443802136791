import { Button } from "@components/atoms/Button";
import MoneySign from "@public/svg/money-sign.svg";
import { StepperStep, Steps } from "@components/molecules/Steps";
import { useRedirectHandler } from "@components/hooks/useRedirectHandler";
import styles from "./index.module.scss";

const steps: StepperStep[] = [
  {
    type: "secondary",
    title:
      "Tevi medī atlases speciālisti? Varbūt tavā darba vietā ir atvērtas vakances?",
  },
  {
    type: "secondary",
    title: "Palīdzi Codelex piesaistīt jaunus sadarbības partnerus",
  },
  {
    type: "secondary",
    title: "Saņem atlīdzību",
  },
];

export interface Props {
  onClickSignUp: () => void;
  isLoading: boolean;
}

export const ReferralSignUpComponent = ({
  onClickSignUp,
  isLoading,
}: Props) => {
  const loading = useRedirectHandler() || isLoading;
  return (
    <div className={styles.wrapper}>
      <div className={styles.referralLogo}>
        <MoneySign />
      </div>
      <h1 className={styles.title}>
        Piesaisti jaunus uzņēmumus un gūsti papildus ienākumus!
      </h1>
      <h3 className={styles.subTitle}>
        Tu esi Codelex absolvents un mēs ļoti ceram, ka Tev patīk Tava esošā
        darba vieta, bet Tevi LinkedIn portālā joprojām uzrunā uzņēmumu HR un
        piedāvā darbu? Mēs piedāvājam Tev atbildēt uz ziņām, iepazīstināt ar
        Codelex un pat saņemt par to atlīdzību! Tieši tik vienkārši!
      </h3>
      <div className={styles.stepsBlock}>
        <h2 className={styles.titleSteps}>Kā tas strādā?</h2>
        <div className={styles.stepsWrap}>
          <Steps steps={steps} type="secondary" />
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button onClick={onClickSignUp} loading={loading}>
          <span className={styles.button}>Sākt tagad!</span>
        </Button>
      </div>
    </div>
  );
};
