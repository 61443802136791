import {
  AccountConnectionStatus,
  AuthConnection,
} from "@domain/account/auth-connections/types";
import { LockUi } from "@components/atoms/LockUi";
import { ConnectionIcon } from "./ConnectionIcon";
import styles from "./index.module.scss";

export type Props = {
  authConnections: { connection: AuthConnection; onClick?: () => void }[];
};

export const AuthConnectionCard = ({ authConnections }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div>
        <h3 className={styles.title}>Autorizācijas metodes</h3>
      </div>
      <div className={styles.linkContainer}>
        <div className={styles.linkWrapper}>
          {authConnections.map(({ connection, onClick }) => {
            if (connection.name === "github" && connection.details) {
              return (
                <div key={connection.name} className={styles.spaceBetween}>
                  <div className={styles.actionWrapper}>
                    <div className={styles.iconWrapper}>
                      <ConnectionIcon connection={connection} />
                    </div>
                    <span className={styles.highLight}>{connection.name}</span>
                  </div>
                  <LockUi
                    forbidden={false}
                    onClick={onClick ? onClick : () => null}
                    lockStatus={
                      connection.connected
                        ? connection.details.authIsUpToDate
                          ? AccountConnectionStatus.CONNECTED
                          : AccountConnectionStatus.UPDATE
                        : AccountConnectionStatus.ALLOWED
                    }
                  />
                </div>
              );
            }
            return (
              <div key={connection.name} className={styles.spaceBetween}>
                <div className={styles.actionWrapper}>
                  <div className={styles.iconWrapper}>
                    <ConnectionIcon connection={connection} />
                  </div>
                  <span className={styles.highLight}>{connection.name}</span>
                </div>
                <LockUi
                  forbidden={false}
                  onClick={onClick ? onClick : () => null}
                  lockStatus={
                    connection.connected
                      ? AccountConnectionStatus.CONNECTED
                      : AccountConnectionStatus.ALLOWED
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
