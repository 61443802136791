import React, { ReactNode } from "react";
import { Timeline } from "antd";
import classNames from "classnames";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Step } from "@components/atoms/Step";
import styles from "./index.module.scss";
import type { ButtonType } from "antd/lib/button";

const { Item: TimelineItem } = Timeline;

export type StepperStep = {
  refetch?: () => Promise<unknown> | undefined;
  completed?: boolean;
  passive?: boolean;
  title?: string;
  description?: string;
  type: "default" | "secondary";
  caption?: string;
  openNewTab?: boolean;
  uploadButton?: {
    show: boolean;
    accountId: string;
    url?: string;
    title: string;
    disabled: boolean;
    toolTipDescription: string;
  };
  button?: {
    type: ButtonType;
    title: string;
    disabled?: boolean;
    onClick?: () => void;
    icon?: ReactNode;
    href?: string;
  };
};
export interface Props {
  type: "default" | "secondary";
  steps: StepperStep[];
}

export const Steps: React.FC<Props> = ({ type, steps }) => {
  return (
    <div
      className={classNames(styles.steps, {
        [styles.stepsSecondary]: type === "secondary",
      })}
    >
      <Timeline>
        {steps.map((it, i) => (
          <TimelineItem
            dot={
              it.passive ? (
                <>
                  {it.completed ? (
                    <CheckCircleOutlined
                      style={{
                        fontSize: "34px",
                        color: "#00fca3",
                      }}
                    />
                  ) : (
                    <div className={styles.dotWaiting}>{i + 1}</div>
                  )}
                </>
              ) : (
                <div className={styles.dot}>{i + 1}</div>
              )
            }
            key={it.description}
          >
            <Step {...it} />
          </TimelineItem>
        ))}
      </Timeline>
    </div>
  );
};
