import { AccountConnectionStatus, AccountExternalConnection } from "./types";

export * from "./types";

export const toStatus = ({
  connection,
}: {
  connection: AccountExternalConnection;
}): AccountConnectionStatus => {
  if (connection.connected) {
    return AccountConnectionStatus.CONNECTED;
  }
  if (connection.forbidden) {
    return AccountConnectionStatus.FORBIDDEN;
  }
  return AccountConnectionStatus.ALLOWED;
};
