import Image from "next/legacy/image";
import { CalendarOutlined } from "@ant-design/icons";
import { Button } from "@components/atoms/Button";
import { formatUTCToLocal } from "@utils/date-time/csr";
import { Props } from "./types";
import styles from "./index.module.scss";

export const Pending = ({
  content,
  interview,
  interviewBookingUrl,
  isConsiderable,
}: Props) => {
  if (interview) {
    const { joiningUrl, rescheduleUrl, cancelUrl } = interview;
    return (
      <div className={styles.pendingWrapper}>
        <div className={styles.leftPartPending}>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{
              __html: content.pending.withInterview.html,
            }}
          />
          <div className={styles.buttonContainer}>
            <div className={styles.buttonWrapper}>
              <Button
                href={joiningUrl || undefined}
                disabled={!joiningUrl}
                type="text"
              >
                Pieslēgties intervijai
              </Button>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                href={rescheduleUrl || undefined}
                disabled={!rescheduleUrl}
                type="text"
              >
                Mainīt intervijas laiku
              </Button>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                style={{ backgroundColor: "#131314" }}
                href={cancelUrl || undefined}
                disabled={!cancelUrl}
                type="text"
              >
                Atcelt interviju
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.rightPartPending}>
          <div className={styles.iconWrapper}>
            <CalendarOutlined
              style={{
                fontSize: "60px",
                color: "#00fca3",
              }}
            />
          </div>
          <p className={styles.descriptionPending}>
            {interview.startTime
              ? formatUTCToLocal({
                  date: interview.startTime,
                  format: "d. MMM. HH:mm",
                })
              : undefined}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.pendingWrapper}>
      <div className={styles.leftPartPending}>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: content.pending.withoutInterview.html,
          }}
        />
        {interviewBookingUrl && isConsiderable ? (
          <div className={styles.buttonWrapper}>
            <Button href={interviewBookingUrl} type="dashed">
              Rezervēt intervijas laiku
            </Button>
          </div>
        ) : null}
      </div>
      <div className={styles.rightPartPending}>
        <div className={styles.iconWrapper}>
          <Image
            src={"/svg/hourglass.svg"}
            alt={"hourglass image"}
            height={61}
            width={41}
          />
        </div>
      </div>
    </div>
  );
};
