import React from "react";
import { useRouter } from "next/router";
import classnames from "classnames";
import { Button } from "@components/atoms/Button";
import CreditCard from "@public/svg/credit-card.svg";
import CheckVector from "@public/svg/check-vector.svg";
import styles from "./index.module.scss";

type Props = {
  isPaymentScheduleAvailable: boolean;
};

const paymentInformation = [
  "kursu apmaksas kārtība",
  "atbalsts",
  "apmaksā kursus ātrāk",
  "atgūsti pārmaksātos nodokļus",
];
export const PaymentBanner = ({ isPaymentScheduleAvailable }: Props) => {
  const router = useRouter();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Informācija par maksājumiem</h1>
        <div className={styles.textWrapper}>
          {paymentInformation.map((text, index) => (
            <div key={index} className={styles.text}>
              <CheckVector className={styles.icon} />
              <p>{text}</p>
            </div>
          ))}
          {isPaymentScheduleAvailable && (
            <div className={styles.text}>
              <CheckVector className={styles.icon} />
              <p>rēķini</p>
            </div>
          )}
        </div>
        <div className={styles.iconWrapper}>
          <CreditCard />
        </div>
        <div
          className={classnames(styles.btnWrapper, {
            [styles.btnWrapperWithPaymentSchedule]: isPaymentScheduleAvailable,
          })}
        >
          <Button onClick={() => router.push("/payments")}>Atvērt</Button>
        </div>
      </div>
    </div>
  );
};
