import { Form, Select } from "antd";
import { countries } from "@constants/countryCodes";

const { Option } = Select;

export const PhonePrefixSelector = () => {
  return (
    <Form.Item name="phoneNumberPrefix" noStyle>
      <Select defaultActiveFirstOption={true} style={{ width: 85 }}>
        {countries.map((item) => (
          <Option key={item.name} value={item.phone.toString()}>
            +{item.phone}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
