import React from "react";
import { useRouter } from "next/router";
import { Button } from "@components/atoms/Button";
import MoneyIcon from "@public/svg/money-sign.svg";
import styles from "./index.module.scss";

export const ReferralBanner = () => {
  const router = useRouter();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Piesaistes programma</h1>
        <div className={styles.btnWrapper}>
          <Button onClick={() => router.push("/referrals")} type="link">
            Uzzināt vairāk
          </Button>
        </div>
        <div className={styles.iconWrapper}>
          <MoneyIcon className={styles.icon} />
        </div>
      </div>
    </div>
  );
};
