import { message as antdMessage } from "antd";

export const copyToClipboard = ({
  content,
  message,
}: {
  content: string;
  message?: string;
}) => {
  navigator.clipboard.writeText(content);
  antdMessage.info(message || "Nokopēts");
};
