import { UpcomingLessonBadge } from "src/components/atoms/UpcomingLessonBadge";
import { UserProfile } from "src/components/atoms/UserProfile";
import { SignOutButton } from "src/components/molecules/SignOutButton";
import { useAccountContext } from "@containers/customer/AccountContext";
import styles from "./index.module.scss";

export type Props = {
  email: string;
  onClickIntroductoryCourse: () => void;
  dateOfStart?: string;
  showUpcommingLesson: boolean;
};

export const UpperHeader = ({
  email,
  onClickIntroductoryCourse,
  dateOfStart,
  showUpcommingLesson,
}: Props) => {
  const { signOut } = useAccountContext();

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        {!showUpcommingLesson || !dateOfStart ? (
          <div className={styles.hallowBadge}>&nbsp;</div>
        ) : (
          <UpcomingLessonBadge
            onClick={onClickIntroductoryCourse}
            upcomingLessonTitle="Nākamās nodarbības sākums"
            date={dateOfStart}
          />
        )}
        <div className={styles.profileWithSignOut}>
          <div className={styles.userProfileWrapper}>
            <div>
              <UserProfile email={email} />
            </div>
          </div>
          <div className={styles.signOut}>
            <SignOutButton onSignOut={signOut} />
          </div>
        </div>
      </div>
    </div>
  );
};
