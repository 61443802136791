import React, { FC } from "react";
import { Form, Checkbox } from "antd";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

type FormOutput = {
  complyWithReservation: boolean;
  complyWithWorkLoad: boolean;
  complyWithEthics: boolean;
};

type Props = {
  onFinish: () => void;
};

export const InterviewBookedApplication: FC<Props> = ({ onFinish }) => {
  const [form] = Form.useForm<FormOutput>();

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <>
      <h1 className={styles.title}>
        Pieteikums Codelex Intensīvajam programmēšanas kursam
      </h1>
      <div className={styles.container}>
        <Form
          form={form}
          name="InterviewBookedApplicationForm"
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            {...formItemLayout}
            name="complyWithReservation"
            valuePropName="checked"
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject("Ja nepiekrīti, nav vērts turpināt..."),
              },
            ]}
          >
            <Checkbox>
              Apstiprinu, ka ieradīšos intervijai paredzētajā laikā vai
              nepieciešamības gadījumā to pārcelšu
            </Checkbox>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            style={{ marginTop: "24px" }}
            name="complyWithWorkLoad"
            valuePropName="checked"
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject("Ja nepiekrīti, nav vērts turpināt..."),
              },
            ]}
          >
            <Checkbox>
              Apstiprinu, ka uz interviju ieradīšos sagatavojies - man būs
              izpildīti maksimāli daudz uzdevumi un varēšu tos atrādīt
              intervētājam;
            </Checkbox>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            style={{ marginTop: "24px" }}
            name="complyWithEthics"
            valuePropName="checked"
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject("Ja nepatīk, mums nav par ko runāt..."),
              },
            ]}
          >
            <Checkbox>Apstiprinu, ka man patīk programmēt</Checkbox>
          </Form.Item>

          <div className={styles.buttonWrapper}>
            <Button htmlType="submit">Turpināt</Button>
          </div>
        </Form>
      </div>
    </>
  );
};
