import React from "react";
import { useRouter } from "next/router";
import styles from "./index.module.scss";

export const ProgrammBanner = () => {
  const router = useRouter();
  return (
    <div onClick={() => router.push("/referrals")} className={styles.container}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>Piesaistes programma</h1>
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.text}>
          Iepazīstini mūs ar uzņēmumiem, kuri gaida darbā Codelex audzēkņus.
          Iesaisties un saņem atlīdzību!
        </p>
      </div>
    </div>
  );
};
