import React from "react";
import { Button } from "@components/atoms/Button";
import VideoIcon from "@public/svg/youtube.svg";
import styles from "./index.module.scss";

export const LatestVideosBanner = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        Podkāsts &quot;Patiesi par IT, karjeru un izglītību&quot;
      </h1>
      <div className={styles.wrapper}>
        <div className={styles.btnWrapper}>
          <Button
            onClick={() =>
              window.open("https://www.youtube.com/@codelex", "_blank")
            }
            type="ghost"
          >
            <span className={styles.btnText}>Uz YouTube</span>
          </Button>
        </div>
        <div className={styles.icon}>
          <VideoIcon />
        </div>
      </div>
    </div>
  );
};
