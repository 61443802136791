import classNames from "classnames";
import { CheckOutlined } from "@ant-design/icons";
import { Button } from "@components/atoms/Button";
import Clock from "@public/svg/clock.svg";
import { formatSeconds } from "@utils/date-time/common";
import styles from "./index.module.scss";

type Props = {
  title: string;
  subTitle?: string;
  text?: string;
  onContinue: () => void;
  timer?: number;
  time: string;
  completed?: boolean;
};

export const CheckYourSkillCard = ({
  title,
  subTitle,
  text,
  onContinue,
  timer,
  time,
  completed,
}: Props) => {
  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.container2, {
          [styles.container2Active]: completed,
        })}
      >
        <CheckOutlined />
      </div>
      <div
        className={classNames({
          [styles.wrapper]: subTitle,
          [styles.wrapperWithDarkBackground]: !subTitle,
        })}
      >
        {timer && timer > 0 && (
          <div className={styles.highlight}>
            Testu varēs atkārtot pēc{" "}
            <span className={styles.highlightTimer}>
              00:
              {formatSeconds(timer, "mm:ss")}
            </span>
          </div>
        )}
        <h1 className={styles.title}>{title}</h1>
        <p className={classNames({ [styles.subTitle]: subTitle })}>
          {" "}
          {subTitle}
        </p>
      </div>
      <div
        className={classNames({
          [styles.textWrapper]: text,
          [styles.textWrapperWithDarkBackground]: !text,
        })}
      >
        <p className={styles.text}>{text}</p>
        <div className={styles.textButtomWrapper}>
          <div
            className={classNames({
              [styles.buttonWrapper]: text,
              [styles.buttonWrapperWithDarkBackground]: !text,
            })}
          >
            <Button
              data-testid={title}
              onClick={onContinue}
              disabled={timer ? timer > 0 : false}
            >
              Turpināt
            </Button>
          </div>
          <span
            className={classNames({
              [styles.time]: text,
              [styles.timeWithDarkBackground]: !text,
            })}
          >
            {" "}
            <Clock /> {time}{" "}
          </span>
        </div>
      </div>
    </div>
  );
};
