import Link from "next/link";
import Image from "next/legacy/image";
import styles from "./index.module.scss";

type MenuItem = {
  title: string;
  path: string;
};

export interface Props {
  menuItems: MenuItem[];
}

export const Footer = ({ menuItems }: Props) => {
  return (
    <footer>
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <div className={styles.navigationLinks}>
            {menuItems.map((item, i) => {
              return (
                <Link key={i} href={item.path}>
                  {item.title}
                </Link>
              );
            })}
          </div>
          <div className={styles.madeIn}>
            Made with{" "}
            <Image
              className={styles.heartSVG}
              src={"/svg/heart.svg"}
              alt="discord image"
              width={16}
              height={15}
            />{" "}
            in latvia
          </div>
          <div className={styles.copyright}>&copy; Codelex 2022</div>
        </div>
      </div>
    </footer>
  );
};
