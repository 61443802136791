import { zonedTimeToUtc } from "date-fns-tz";

export const START_HOUR_RIGA_TIME = 18;
export const RIGA_TIME_ZONE = "Europe/Riga";

export const systemOffset = (): number => {
  return -new Date().getTimezoneOffset() / 60;
};

export const startHourUTC = (date: Date) => {
  const targetTimeInRiga = new Date(date);
  const hours = START_HOUR_RIGA_TIME - systemOffset();
  targetTimeInRiga.setHours(hours, 0, 0, 0);
  return zonedTimeToUtc(targetTimeInRiga, RIGA_TIME_ZONE).getHours();
};
