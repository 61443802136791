import { Row, Col } from "antd";
import React from "react";
import { HeaderLogo } from "src/components/atoms/HeaderLogo";
import styles from "./index.module.scss";

export const SingleActionLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <div className={styles.wrapper}>
      <Row className={styles.wrapperHeader}>
        <Col
          xs={{ offset: 1, span: 22 }}
          sm={{ offset: 2, span: 20 }}
          md={{ offset: 2, span: 20 }}
          lg={{ offset: 2, span: 20 }}
          xl={{ offset: 3, span: 18 }}
          xxl={{ offset: 0, span: 24 }}
        >
          <div className={styles.capWrapper}>
            <div className={styles.largeScreenCap}>
              <HeaderLogo link="/" />
            </div>
          </div>
        </Col>
      </Row>

      <div className={styles.center}>{children}</div>
    </div>
  );
};
