import { z } from "zod";
import { Language } from "@domain/types";

export interface ReferralStatistics {
  __typename: string;
  referralId: string;
  link: string;
  clicks: number;
  balance: number;
  entries: ReferralStatisticEntry[];
}

export type ReferralEntryComment = {
  created: string;
  content: string;
};

export interface RestrictedAccess {
  __typename: string;
  forbidden: boolean;
}

export type ReferralStatisticsResult = ReferralStatistics | RestrictedAccess;

export interface MessageTemplate {
  language: Language;
  content: string;
  copyText?: string;
}

export interface Terms {
  date: string;
  html: string;
}

export enum ReferralType {
  REDIRECT = "REDIRECT",
  MANUAL = "MANUAL",
}

const ReferralTypeSchema = z.enum([ReferralType.REDIRECT, ReferralType.MANUAL]);

export const ReferralEntryCommentSchema = z.object({
  created: z.string(),
  content: z.string(),
});

export interface ReferralStatisticEntry {
  id: string;
  date: string;
  type: ReferralType;
  ipAddress: string;
  userAgent: string;
  meetingBooked: boolean;
  company: string | null;
  comments: ReferralEntryComment[];
}

export const ReferralStatisticEntrySchema = z.object({
  id: z.string(),
  date: z.string().optional(),
  type: ReferralTypeSchema.optional(),
  ipAddress: z.string().optional(),
  userAgent: z.string().optional(),
  meetingBooked: z.boolean().optional(),
  company: z.string().optional().nullable(),
  comments: z.array(ReferralEntryCommentSchema).optional(),
});

export type ReferralStatisticEntryTypes = z.infer<
  typeof ReferralStatisticEntrySchema
>;

const AddReferralManuallyVariablesSchema = z.object({
  company: z.string(),
  comments: z.string(),
});

export type AddReferralManuallyVariables = z.infer<
  typeof AddReferralManuallyVariablesSchema
>;
