const ALL_AUTH_CONNECTION_TYPES = [
  "facebook",
  "google",
  "email",
  "github",
] as const;

export type AuthConnectionType = (typeof ALL_AUTH_CONNECTION_TYPES)[number];

export type AuthConnection = {
  name: AuthConnectionType;
  connected: boolean;
  details: GithubAuthConnectionDetails | null;
};

export interface GithubAuthConnectionDetails {
  __typename: string;
  username: string;
  invited: boolean;
  member: boolean;
  authIsUpToDate: boolean;
}

export enum AccountConnectionStatus {
  FORBIDDEN = "FORBIDDEN",
  ALLOWED = "ALLOWED",
  CONNECTED = "CONNECTED",
  UPDATE = "UPDATE",
}
