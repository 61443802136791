import Image from "next/legacy/image";
import Link from "next/link";
import { Button } from "src/components/atoms/Button";
import styles from "./index.module.scss";

export type Props = {
  passed: boolean;
  button: {
    titlePrimary: string;
    onClickPrimary: () => void;
    titleSecondary?: string;
    onClickSecondary?: () => void;
  };
};

export const SuccessfulGameView = ({ button, passed }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrapper}>
        <h1 className={styles.title}>Apsveicam!</h1>
        {!passed ? (
          <p className={styles.description}>Visi līmeņi izieti!</p>
        ) : (
          <p className={styles.description}>
            Esi lieliski parādījis savas prasmes!
            <br />
            <br />
            Lūdzu, dodies tālāk un{" "}
            <Link href={"/courses/programming-for-beginners"}>
              Typescript pamatiem
            </Link>
            iepazīsties ar, lai sagatavotos{" "}
            <Link href={"/start"}>Ievadkursam</Link>.
            <br />
            <br />
          </p>
        )}
      </div>
      <div className={styles.svgWrapper}>
        <Image
          src={"/svg/high-five.svg"}
          className={styles.highFiveSvg}
          alt="high-five icon"
          height={238}
          width={238}
        />
      </div>
      <div className={styles.buttonWrapper}>
        {button.titlePrimary && (
          <Button type="default" onClick={button.onClickPrimary}>
            {button.titlePrimary}
          </Button>
        )}
        {button.titleSecondary && (
          <Button type="default" onClick={button.onClickSecondary}>
            {button.titleSecondary}
          </Button>
        )}
      </div>
    </div>
  );
};
