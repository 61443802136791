import Image from "next/legacy/image";
import { Button } from "src/components/atoms/Button";
import styles from "./index.module.scss";

export type Props = {
  button: {
    titlePrimary: string;
    onClickPrimary: () => void;
    titleSecondary?: string;
    onClickSecondary?: () => void;
  };
};

export const SuccessfulQuizView = ({ button }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>Apsveicam!</h1>
      </div>
      <div className={styles.messageWrapper}>
        <div>
          <span className={styles.highlightText}>
            Esi lieliski parādījis savas prasmes!
          </span>
          <p className={styles.message}>Lūdzu, dodies tālāk uz Ievadkursu.</p>
        </div>
        <div className={styles.buttonsWrapper}>
          <Button type="default" onClick={button.onClickPrimary}>
            {button.titlePrimary}
          </Button>
          {!button.titleSecondary ||
            (!button.onClickSecondary && (
              <Button type="default" onClick={button.onClickSecondary}>
                {button.titleSecondary}
              </Button>
            ))}
        </div>
      </div>
      <div className={styles.imageWrapper}>
        <Image
          src={"/svg/high-five.svg"}
          className={styles.highFiveIcon}
          alt="high-five icon"
          height={238}
          width={238}
        />
      </div>
    </div>
  );
};
