import React, { FC, useEffect } from "react";
import Link from "next/link";
import SadIcon from "@public/svg/sad.svg";
import { AuthErrorCode, parseAuthErrorCode } from "@domain/auth";
import { logError } from "@integration/errors/bugsnag/csr";
import styles from "./index.module.scss";

type Props = {
  error?: AuthErrorCode | string;
  authorized: boolean;
};

export const AuthFailed: FC<Props> = ({ error, authorized }) => {
  useEffect(() => {
    if (error && parseAuthErrorCode(error) === undefined) {
      logError(new Error(`Unknown auth error code: ${error}`));
    }
  }, [error]);
  const errorDetails = ({ error, authorized }: Props) => {
    let body = null;
    if (error === "account_exists") {
      body = (
        <span className={styles.desc}>
          Šķiet, ka autorizācijas metode jau ir piesaistīta citam kontam. Lūdzu,
          pārbaudi vai neesi izveidojis divus kontus un ja tā - dzēs vienu no
          tiem.
        </span>
      );
    }
    if (!body) {
      body = (
        <span className={styles.desc}>
          Ir notikusi tehniska kļūda, veicot autentifikāciju. Lūdzu mēģini
          vēlreiz.
        </span>
      );
    }
    return (
      <>
        {body}
        {authorized ? (
          <div className={styles.mailButtonWrapper}>
            <Link href={"/my-account"}>Pārvaldīt kontu</Link>
          </div>
        ) : (
          <div className={styles.mailButtonWrapper}>
            <Link href={"/sign-in"}>Mēģini pierakstīties vēlreiz</Link>
          </div>
        )}
        <span className={styles.desc2}>
          Ja problēma atkārtojas, lūdzu sazinies ar Codelex klientu atbalsta
          komandu info@codelex.io.
        </span>
      </>
    );
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.headWrapper}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>Oups...</h1>
        </div>
        <div
          className={styles.descWrapper}
          data-testid="sign-in-failed-message"
        >
          {errorDetails({ error, authorized })}
        </div>
      </div>
      <div className={styles.cardWrapper}>
        <h1 className={styles.titleMobile}>Oups...</h1>
        <SadIcon />
      </div>
    </div>
  );
};
