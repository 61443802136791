import { Spinner } from "@components/atoms/Spinner";
import { SingleActionLayout } from "@components/layouts/SingleActionLayout";

export const LoadingNoNavigation = () => {
  return (
    <SingleActionLayout>
      <Spinner />
    </SingleActionLayout>
  );
};
