import React, { FC } from "react";
import Link from "next/link";
import { PrepaidCoursePricing } from "@domain/static-courses/types";
import { Button } from "@components/atoms/Button";
import { UnorderedListSmall } from "@components/atoms/UnorderedListSmall";
import DiscountIcon from "@public/svg/discount-90.svg";
import styles from "./index.module.scss";

const bulletPointText = [
  {
    id: 1,
    text: "  gada laikā pēc kursa pirkuma Tu tiec uzņemts Intensīvajā programmēšanas kursā",
  },
  {
    id: 2,
    text: "pabeidzot Intensīvo kursu, sāc strādāt kā programmētājs",
  },
];

type Props = {
  allPricing: PrepaidCoursePricing[];
  onBuyNow: () => void;
};

export const ConditionsForDiscount: FC<Props> = ({ allPricing, onBuyNow }) => {
  const prices = allPricing.map((pricing) => pricing.price);
  const discountPrice = Math.min(...prices);
  const fullPrice = Math.max(...prices);
  const isDiscountAvailable = discountPrice !== fullPrice;

  const cutDiscountPrice = (0.9 * discountPrice).toFixed(2).replace(".", ",");
  const cutFullPrice = (0.9 * fullPrice).toFixed(2).replace(".", ",");

  const discountFirstPayment = (0.1 * discountPrice)
    .toFixed(2)
    .replace(".", ",");
  const fullPriceFirstPayment = (0.1 * fullPrice).toFixed(2).replace(".", ",");

  return (
    <div className={styles.container}>
      <DiscountIcon className={styles.iconMobile} />
      <div className={styles.wrapper}>
        <h4 className={styles.title}>90% atlaide kursam ir spēkā, ja:</h4>

        {bulletPointText.map((item) => (
          <UnorderedListSmall key={item.id}>{item.text}</UnorderedListSmall>
        ))}
        <div className={styles.titleWrapper}>
          <h4 className={styles.title}>Kā tiks atmaksāta atlaides summa? </h4>
        </div>
        <div className={styles.descWrapper}>
          <p className={styles.desc}>
            Pabeidzot{" "}
            <span className={styles.link}>
              <Link href={"https://www.codelex.io/intensivais-kurss"}>
                Intensīvo Kursu
              </Link>{" "}
            </span>
            {isDiscountAvailable ? (
              <>
                un sākot strādāt, Tu atgūsti 90% no kursa cenas 1-2 mēnešus
                neveicot apmaksu par Intensīvā kursa mācībām (Codelex neieturēs
                20% no mēnešalgas līdz summa sasniegs 90% no kursa jeb{" "}
                {cutDiscountPrice}€ vai {cutFullPrice}€). Līdz ar to kurss Tev
                būs izmaksājis {discountFirstPayment}€ vai{" "}
                {fullPriceFirstPayment}€, atkarībā no tā sākotnējās cenas.
              </>
            ) : (
              <>
                {" "}
                un sākot strādāt, Tu atgūsti 90% no kursa cenas 1-2 mēnešus
                neveicot apmaksu par Intensīvā kursa mācībām (Codelex neieturēs
                20% no mēnešalgas līdz summa sasniegs 90% no kursa jeb{" "}
                {cutFullPrice}€). Līdz ar to kurss Tev būs izmaksājis{" "}
                {fullPriceFirstPayment}€, atkarībā no tā sākotnējās cenas.
              </>
            )}
          </p>
        </div>
        <div className={styles.buttonWrapper}>
          <Button onClick={onBuyNow} type="default">
            Iegādāties
          </Button>
        </div>
      </div>
      <div className={styles.iconWrapper}>
        <DiscountIcon className={styles.icon} />
      </div>
    </div>
  );
};
