import Image, { StaticImageData } from "next/image";
import classNames from "classnames";
import aiImage from "@public/ai.jpg";
import typescriptCourseImage from "@public/typescript-course.jpg";
import typescriptBeginnerImage from "@public/typescript-beginners.jpg";
import nextBanner from "@public/nextBanner.png";
import githubImage from "@public/github.jpg";
import { StaticCourseId } from "@domain/static-courses/types";
import styles from "./index.module.scss";

export type Props = {
  withBadge?: boolean;
  comingSoon?: boolean;
  inProccess: boolean;
  onNavigate: () => void;
  title: string;
  desc: string;
  courseId: StaticCourseId;
};

export const CourseCard = ({
  comingSoon,
  withBadge,
  inProccess = false,
  onNavigate,
  title,
  desc,
  courseId,
}: Props) => {
  const images: Record<StaticCourseId, StaticImageData> = {
    "programming-basics-with-ts": typescriptBeginnerImage,
    "programming-for-beginners": typescriptCourseImage,
    "learn-with-ai": aiImage,
    "git-and-github": githubImage,
    next: nextBanner,
  };
  const imageToUse = images[courseId] || typescriptCourseImage;

  return (
    <div
      className={`${styles.container} ${comingSoon && styles.comingSoon}`}
      onClick={comingSoon ? undefined : () => onNavigate()}
    >
      <Image
        alt="background"
        src={imageToUse}
        placeholder="blur"
        className={styles.backgroundImage}
        width={285}
        height={190}
        quality={100}
        priority
      />
      {withBadge && <div className={styles.badge}>Jaunums</div>}
      {comingSoon && <div className={styles.badge}>Drīzumā</div>}
      {inProccess && (
        <div
          className={classNames(styles.inProcessBadge, {
            [styles.secondBadge]: withBadge,
          })}
        >
          Izstrādē
        </div>
      )}
      <div className={styles.textWrapper}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{desc}</p>
      </div>
    </div>
  );
};
