import React, { FC } from "react";
import { Button } from "@components/atoms";
import { differenceInMinutes } from "@utils/date-time/common";
import { useHydrationSafeDateAsNumber } from "@components/hooks/use-hydration-safe-date";
import { startHourUTC } from "@domain/intro-course/utils";
import styles from "./index.module.scss";

export type Props = {
  text: string | React.ReactNode;
  button?: {
    text: string;
    link: string | URL | undefined;
  };
  startTime: string | undefined | null;
};

export const JoinCallBanner: FC<Props> = ({ text, button, startTime }) => {
  const currentTime = useHydrationSafeDateAsNumber(new Date());
  const startTimeZoned = useHydrationSafeDateAsNumber(
    startTime ? new Date(startTime) : null,
  );

  const minutesTillStart = React.useMemo(() => {
    if (!startTime || !startTimeZoned) {
      return null;
    }
    const startDateWithTime = new Date(startTime);
    startDateWithTime.setHours(startHourUTC(startDateWithTime), 0, 0, 0);
    return differenceInMinutes(startTimeZoned, currentTime);
  }, [startTime, startTimeZoned, currentTime]);

  const isButtonDisabled = React.useMemo(() => {
    return (
      !button?.link ||
      !startTime ||
      (minutesTillStart !== null && minutesTillStart > 15)
    );
  }, [button?.link, startTime, minutesTillStart]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>{text}</div>
      {button && (
        <div className={styles.buttonWrapper}>
          <Button
            type="dashed"
            disabled={isButtonDisabled}
            onClick={() => button.link && window.open(button.link, "_blank")}
          >
            {button.text}
          </Button>
        </div>
      )}
    </div>
  );
};
