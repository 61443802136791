import React from "react";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

export const BlogBanner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Lasi jaunākos rakstus Codelex blogā</h1>
        <div className={styles.btnWrapper}>
          <Button
            onClick={() =>
              window.open("https://www.codelex.io/blogs", "_blank")
            }
            type="link"
          >
            <span className={styles.btnText}>Uz blogu</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
