import React, { FC } from "react";
import Image from "next/legacy/image";
import { Form, Input } from "antd";
import { Button } from "@components/atoms/Button";
import NotificationIcon from "@public/svg/notification.svg";
import styles from "./index.module.scss";

const { TextArea: AntDTextArea } = Input;

export interface AddCommentFormOutput {
  content: string;
}

export type Props = {
  onAddComment: (values: AddCommentFormOutput) => void;
  loading: boolean;
  onClose: () => void;
};

export const AddComment: FC<Props> = ({ onAddComment, onClose, loading }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.close} onClick={onClose}>
        <Image
          src={"/svg/close.svg"}
          alt={"close image"}
          height={18}
          width={18}
        />
      </div>
      <div className={styles.envelopeWrapper}>
        <NotificationIcon className={styles.envelopeIcon} />
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.descWrapper}>
          <span className={styles.cardDesc}>Pievieno komentāru</span>
        </div>

        <Form
          layout="vertical"
          style={{ paddingTop: "15px" }}
          wrapperCol={{ span: 24 }}
          onFinish={onAddComment}
        >
          <Form.Item
            name="content"
            rules={[
              { required: true, type: "string", message: "Ievadi komentāru!" },
            ]}
          >
            <AntDTextArea
              rows={4}
              spellCheck={false}
              style={{
                opacity: "1",
                color: "rgba(255, 255, 255, 0.7)",
                height: "255px",
                paddingLeft: "12px",
                paddingTop: "16px",
              }}
              placeholder="Komentārs"
              disabled={false}
            />
          </Form.Item>

          <div className={styles.buttonWrapper}>
            <Button disabled={loading} type="default" htmlType="submit">
              Apstiprināt
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};
