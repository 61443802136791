import Image from "next/legacy/image";
import { Button } from "src/components/atoms/Button";
import styles from "./index.module.scss";

type NextJoinProps = {
  onPrimaryClick: () => void;
  loading?: boolean;
};

export const NextWelcome = ({
  onPrimaryClick,
  loading = false,
}: NextJoinProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrapper}>
        <h1 className={styles.title}>Sveicināts!</h1>
        <h2 className={styles.subTitle}>
          Prieks, ka esi izvēlējies Next programmu!
        </h2>
        <p className={styles.text}>
          Mēs esam gandarīti, ka izvēlējies pievienoties Codelex Next programmai
          un spert nākamo soli tuvāk savam mērķim!
          <br /> <br />
          Next programmā Tev būs iespēja patstāvīgi veikt dažādus uzdevumus,
          kurus pēc iesniegšanas izskatīs un izvērtēs kāds no Next mentoriem.
          <br />
          <br />
          Codelex piedāvā dažādus resursus un iespējas mācīties programmēt gan
          interesentiem ar programmēšanas iemaņām, gan tiem, kas programmēšanu
          vēlas apgūt no pašiem pamatiem, piemēram, Codelex ikmēneša
          Ievadnodarbībās.
          <br />
          <br />
          <br />
          <br />
        </p>
        <div className={styles.buttonWrapper}>
          <Button type="default" onClick={onPrimaryClick} disabled={loading}>
            Sākt mācības
          </Button>
        </div>
      </div>
      <div className={styles.imageWrapper}>
        <Image
          src={"/svg/high-five.svg"}
          className={styles.highFiveIcon}
          alt="high-five icon"
          height={238}
          width={238}
        />
      </div>
    </div>
  );
};
