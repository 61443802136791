import { AccountConnectionStatus, AuthConnection } from "./types";

export const toStatus = ({
  connection,
}: {
  connection: AuthConnection;
}): AccountConnectionStatus => {
  if (connection.connected) {
    return AccountConnectionStatus.CONNECTED;
  }
  return AccountConnectionStatus.FORBIDDEN;
};
