export const countries = [
  { name: "Aland Islands", code: "AX", phone: 358 },
  { name: "Albania", code: "AL", phone: 355 },
  { name: "Andorra", code: "AD", phone: 376 },
  { name: "Austria", code: "AT", phone: 43 },
  { name: "Belarus", code: "BY", phone: 375 },
  { name: "Belgium", code: "BE", phone: 32 },
  { name: "Bosnia and Herzegovina", code: "BA", phone: 387 },
  { name: "Bulgaria", code: "BG", phone: 359 },
  { name: "Croatia", code: "HR", phone: 385 },
  { name: "Czech Republic", code: "CZ", phone: 420 },
  { name: "Denmark", code: "DK", phone: 45 },
  { name: "Estonia", code: "EE", phone: 372 },
  { name: "Faroe Islands", code: "FO", phone: 298 },
  { name: "Finland", code: "FI", phone: 358 },
  { name: "France", code: "FR", phone: 33 },
  { name: "Germany", code: "DE", phone: 49 },
  { name: "Gibraltar", code: "GI", phone: 350 },
  { name: "Greece", code: "GR", phone: 30 },
  { name: "Guernsey", code: "GG", phone: 44 },
  { name: "Holy See (Vatican City State)", code: "VA", phone: 39 },
  { name: "Hungary", code: "HU", phone: 36 },
  { name: "Iceland", code: "IS", phone: 354 },
  { name: "Ireland", code: "IE", phone: 353 },
  { name: "Isle of Man", code: "IM", phone: 44 },
  { name: "Italy", code: "IT", phone: 39 },
  { name: "Jersey", code: "JE", phone: 44 },
  { name: "Kosovo", code: "XK", phone: 381 },
  { name: "Latvia", code: "LV", phone: 371 },
  { name: "Liechtenstein", code: "LI", phone: 423 },
  { name: "Lithuania", code: "LT", phone: 370 },
  { name: "Luxembourg", code: "LU", phone: 352 },
  {
    name: "Macedonia, the Former Yugoslav Republic of",
    code: "MK",
    phone: 389,
  },
  { name: "Malta", code: "MT", phone: 356 },
  { name: "Moldova, Republic of", code: "MD", phone: 373 },
  { name: "Monaco", code: "MC", phone: 377 },
  { name: "Montenegro", code: "ME", phone: 382 },
  { name: "Netherlands", code: "NL", phone: 31 },
  { name: "Norway", code: "NO", phone: 47 },
  { name: "Poland", code: "PL", phone: 48 },
  { name: "Portugal", code: "PT", phone: 351 },
  { name: "Romania", code: "RO", phone: 40 },
  { name: "San Marino", code: "SM", phone: 378 },
  { name: "Serbia", code: "RS", phone: 381 },
  { name: "Serbia and Montenegro", code: "CS", phone: 381 },
  { name: "Slovakia", code: "SK", phone: 421 },
  { name: "Slovenia", code: "SI", phone: 386 },
  { name: "Spain", code: "ES", phone: 34 },
  { name: "Svalbard and Jan Mayen", code: "SJ", phone: 47 },
  { name: "Sweden", code: "SE", phone: 46 },
  { name: "Switzerland", code: "CH", phone: 41 },
  { name: "Ukraine", code: "UA", phone: 380 },
  { name: "United Kingdom", code: "GB", phone: 44 },
];
