import { Button } from "src/components/atoms/Button";
import { differenceInMinutes, formatDateNative } from "@utils/date-time/common";
import { useHydrationSafeDateAsNumber } from "@components/hooks/use-hydration-safe-date";
import { startHourUTC } from "@domain/intro-course/utils";
import styles from "./index.module.scss";

export interface Props {
  startTime: number;
  onJoin: () => void;
}

export const ZoomMeeting = ({ startTime, onJoin }: Props) => {
  const startDateWithTime = new Date(startTime);
  startDateWithTime.setHours(
    startHourUTC(new Date(startDateWithTime)),
    0,
    0,
    0,
  );
  const startTimeZoned = useHydrationSafeDateAsNumber(startDateWithTime);
  const currentTime = useHydrationSafeDateAsNumber(new Date());
  const minutesTillStart = differenceInMinutes(startTimeZoned, currentTime);
  return (
    <>
      <div className={styles.dateAndTimeBlock}>
        <p className={styles.subText}>Datums: </p>
        <span className={styles.dateAndTime} suppressHydrationWarning>
          {formatDateNative(startTimeZoned)}
        </span>
      </div>
      <div className={styles.dateAndTimeBlock}>
        <p className={styles.subText}>Laiks: </p>
        <span className={styles.dateAndTime} suppressHydrationWarning>
          {"18:00"}
        </span>
      </div>
      <div className={styles.buttonBlock}>
        <p className={styles.subText}>Zoom links: </p>
        <div className={styles.joinButton}>
          <Button disabled={minutesTillStart > 15} onClick={onJoin}>
            Pievienoties nodarbībai
          </Button>
        </div>
        <p className={styles.descText}>
          * Nodarbībai varēs pieslēgties neilgi pirms nodarbības sākuma.
        </p>
      </div>
      {!onJoin && (
        <p className={styles.linkDescription}>
          Links būs aktīvs 15 minūtes pirms nodarbības sākuma
        </p>
      )}
    </>
  );
};
