import React, { FC } from "react";
import Link from "next/link";
import { Button } from "@components/atoms/Button";
import { createSniperLink } from "@utils/email";
import styles from "./index.module.scss";

type Props = {
  email: string;
  redirectToSendAgain: () => void;
};

export const SignInWithEmail: FC<Props> = ({ email, redirectToSendAgain }) => {
  const isGmail = email?.includes("gmail");
  return (
    <div className={styles.wrapper}>
      <div className={styles.headWrapper}>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title} data-testid="confirm-email">
            Apstiprināt reģistrāciju
          </h1>
        </div>
        <div className={styles.descWrapper}>
          <span className={styles.desc}>
            Uz Tavu e-pastu tika nosūtīta apstiprinājuma ziņa - dodies uz&nbsp;
            <span className={styles.highLight}>{email}</span>, lai apstiprinātu
            reģistrāciju.
          </span>
          <span className={styles.desc2}>
            {isGmail && (
              <>
                Ja Tev ir gmail, esam sagatavojuši ērtībai linku. Spied uz pogas
                zemāk un tas Tevi aizvedīs uz{" "}
                <span className={styles.highLight}>{email}</span> iesūtni.
              </>
            )}
          </span>
        </div>
        {!isGmail && <div className={styles.hallowButton}>&nbsp;</div>}

        {email && isGmail && (
          <div className={styles.mailButtonWrapper}>
            <Link href={createSniperLink({ email })} target="_blank">
              Doties uz Gmail
            </Link>
          </div>
        )}
      </div>

      <div className={styles.cardWrapper}>
        <div className={styles.descWrapperCard}>
          <span className={styles.desc}>
            Nevari atrast reģistrācijas apstiprinājuma e-pastu? Spied uz pogas
            un veic atkārtotu reģistrēšanos.
          </span>
        </div>

        <div className={styles.repeatButtonWrapper}>
          <Button
            onClick={redirectToSendAgain}
            style={{ fontSize: "14px" }}
            type="ghost"
            htmlType="submit"
          >
            Uz sākumu
          </Button>
        </div>
      </div>
    </div>
  );
};
