import React from "react";
import { Tooltip } from "antd";
import classNames from "classnames";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

export type Props = {
  email?: string;
  onChangeEmail?: () => void;
  onAddEmail?: () => void;
};

export const AccountEmailCard = ({
  email,
  onChangeEmail,
  onAddEmail,
}: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.emailWrapper}>
        <h3
          className={classNames(styles.title, {
            [styles.titleAndEmail]: email,
          })}
        >
          E-pasts
        </h3>
        <Tooltip title={email} placement="bottom">
          {email && (
            <span className={styles.email} data-testid="user-profile-email">
              {email}
            </span>
          )}
        </Tooltip>
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.buttonWrapper}>
          <Button type="default" disabled={!!email} onClick={onAddEmail}>
            Pievienot e-pastu
          </Button>
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            type="default"
            onClick={onChangeEmail}
            disabled={!onChangeEmail}
          >
            Mainīt e-pastu
          </Button>
        </div>
      </div>
    </div>
  );
};
