import React from "react";
import classnames from "classnames";
import { differenceInMilliseconds } from "@utils/date-time/common";
import { PreviousLessonVideo } from "@components/atoms";
import styles from "./index.module.scss";

type LessonRecording = {
  date: Date;
  videoUrl: string;
  courseTitle: string;
  title: string;
};

export interface Props {
  lessons: LessonRecording[];
}

export const PreviousLessons: React.FC<Props> = ({ lessons }) => {
  const lessonsByMonth = lessons
    .sort((b, a) => differenceInMilliseconds(a.date, b.date))
    .reduce((lessonsObject: { [key: string]: LessonRecording[] }, lesson) => {
      lessonsObject[lesson.courseTitle]
        ? lessonsObject[lesson.courseTitle].push(lesson)
        : (lessonsObject[lesson.courseTitle] = [lesson]);
      return lessonsObject;
    }, {});

  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.wrapperForTwoMonths]: Object.keys(lessonsByMonth).length === 2,
      })}
    >
      {Object.entries(lessonsByMonth).map(([month, lessons], i) => {
        return (
          <div key={i}>
            <div className={styles.titleMonth} key={`${month}${i}`}>
              {month}
            </div>
            <div className={styles.lessons}>
              {lessons
                .sort((a, b) => differenceInMilliseconds(a.date, b.date))
                .map((lesson, index) => (
                  <PreviousLessonVideo
                    lessonTitle={lesson.title}
                    src={lesson.videoUrl}
                    key={index}
                  />
                ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};
