import React, { FC } from "react";
import Image from "next/legacy/image";
import { Button } from "@components/atoms/Button";
import NotificationIcon from "@public/svg/notification.svg";
import { ReferralStatisticEntry } from "@domain/referrals/csr";
import { formatDateNative } from "@utils/date-time/common";
import styles from "./index.module.scss";

type Props = {
  entry: Pick<ReferralStatisticEntry, "company" | "comments">;
  onClose: () => void;
  onAddComment: () => void;
};

export const ReferralComments: FC<Props> = ({
  entry,
  onClose,
  onAddComment,
}) => {
  const { comments, company } = entry;
  return (
    <div className={styles.wrapper}>
      <div className={styles.close} onClick={onClose}>
        <Image
          src={"/svg/close.svg"}
          alt={"close image"}
          height={18}
          width={18}
        />
      </div>
      <div className={styles.envelopeWrapper}>
        <NotificationIcon className={styles.envelopeIcon} />
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.descWrapper}>
          <span className={styles.cardDesc}>
            {company && `Komentāri priekš ${company}`}
          </span>
        </div>
        <div className={styles.comments}>
          {comments.map((comment, i) => (
            <div key={i} className={styles.comment}>
              <div className={styles.content}>{comment.content}</div>

              <div className={styles.date} suppressHydrationWarning>
                {formatDateNative(new Date(comment.created))}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.buttonWrapper}>
          <Button type="default" onClick={onAddComment}>
            Pievienot komentāru
          </Button>
        </div>
      </div>
    </div>
  );
};
