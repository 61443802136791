import React, { FC } from "react";
import { Button } from "@components/atoms/Button";
import { UnorderedListSmall } from "@components/atoms/UnorderedListSmall";
import { VimeoPlayer } from "@components/molecules/VimeoPlayer";
import styles from "./index.module.scss";

const bulletPointText = [
  {
    id: 1,
    text: "vēlies kārtīgi sagatavoties nopietnām apmācībām Intensīvajā kursā",
  },
  {
    id: 2,
    text: "vēlies papildus praktizēt mentoru sastādītus programmēšanas uzdevumus",
  },
  {
    id: 3,
    text: "nejūties pārliecināts par savām spējām pirms intervijas un vēlies papildus praktizēt uzdevumus",
  },
];

type Props = {
  onBuyNow: () => void;
};

export const CtaVideoCourse: FC<Props> = ({ onBuyNow }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.video}>
        <VimeoPlayer
          videoUrl={"https://www.youtube.com/watch?v=D82Xfk83-fw&t=1s"}
        />
      </div>
      <div className={styles.actionWrapper}>
        <div className={styles.titleWrapper}>
          <h4 className={styles.title}>Šis kurss Tev būs piemērots, ja:</h4>
        </div>
        <div className={styles.listWrapper}>
          {bulletPointText.map((item) => (
            <UnorderedListSmall key={item.id}>{item.text}</UnorderedListSmall>
          ))}
        </div>
        <div className={styles.buyNowButtonWrapper}>
          <Button onClick={onBuyNow} type="default">
            Iegādāties
          </Button>
        </div>
      </div>
    </div>
  );
};
