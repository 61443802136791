import React from "react";
import { Button } from "@components/atoms/Button";
import styles from "./index.module.scss";

type Props = {
  text: string;
  buttonText: string;
  onClick: () => void;
};

export const InformativeBanner = ({ text, buttonText, onClick }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.titlteWrapper}>
          <h1 className={styles.title}>{text} </h1>
        </div>
        <div className={styles.btnWrapper}>
          <Button onClick={onClick} type="link">
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};
