import React, { useEffect, useRef, useState } from "react";
import Image from "next/legacy/image";
import classnames from "classnames";
import { Graduate } from "@domain/job-seekers/types";
import Portfolio from "@public/svg/portfolio-small.svg";
import Education from "@public/svg/light-bulb.svg";
import styles from "./index.module.scss";

type Props = {
  workingGraduate: Graduate;
};

export const HiredDeveloperCard = ({ workingGraduate }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [sliderState, setSliderState] = useState({
    endReached: false,
    startReached: true,
  });
  const [, setPrevContainerWidth] = useState({
    additionalInformation: containerRef.current?.clientWidth,
  });

  const handleResize = () => {
    const additionalInfoContainer = containerRef.current;
    const windowWidth = window.innerWidth;
    if (windowWidth < 480) {
      if (additionalInfoContainer) {
        handleContainerResize();
      }
    } else {
      if (additionalInfoContainer) {
        if (
          additionalInfoContainer.scrollWidth <=
          additionalInfoContainer.clientWidth
        ) {
          setSliderState((prevState) => ({
            ...prevState,
            endReached: true,
          }));
        }
        if (
          additionalInfoContainer.scrollWidth >
            additionalInfoContainer.clientWidth &&
          additionalInfoContainer.scrollLeft < 1
        ) {
          setSliderState((prevState) => ({
            ...prevState,
            endReached: false,
          }));
        }
      }
    }
  };

  useEffect(() => {
    const additionalInfoContainer = containerRef.current;
    if (additionalInfoContainer) {
      setSliderState((prevState) => ({
        ...prevState,
        endReached:
          additionalInfoContainer.scrollWidth <=
          additionalInfoContainer.clientWidth,
      }));
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleContainerResize = () => {
    const additionalInfoContainer = containerRef.current;
    if (additionalInfoContainer) {
      const currentWidth = additionalInfoContainer.clientWidth;
      setPrevContainerWidth(
        (
          prevWidth,
        ): {
          additionalInformation: number | undefined;
        } => {
          if (prevWidth.additionalInformation !== undefined) {
            if (currentWidth > prevWidth.additionalInformation) {
              if (
                additionalInfoContainer.scrollWidth -
                  additionalInfoContainer.clientWidth >
                0
              ) {
                setSliderState((prevState) => ({
                  ...prevState,
                  endReached: false,
                }));
              }
              if (sliderState.endReached) {
                setSliderState((prevState) => ({
                  ...prevState,
                  endReached: true,
                }));
              }
              if (additionalInfoContainer.scrollLeft > 0) {
                setSliderState((prevState) => ({
                  ...prevState,
                  startReached: false,
                }));
              } else {
                setSliderState((prevState) => ({
                  ...prevState,
                  startReached: true,
                }));
              }
            } else if (currentWidth < prevWidth.additionalInformation) {
              if (additionalInfoContainer.scrollLeft < 1) {
                setSliderState((prevState) => ({
                  ...prevState,
                  startReached: true,
                }));
              } else {
                setSliderState((prevState) => ({
                  ...prevState,
                  startReached: false,
                }));
              }
              if (
                additionalInfoContainer.scrollWidth -
                  additionalInfoContainer.clientWidth >
                0
              ) {
                setSliderState((prevState) => ({
                  ...prevState,
                  endReached: false,
                }));
              } else {
                setSliderState((prevState) => ({
                  ...prevState,
                  endReached: true,
                }));
              }
            }
          }
          return {
            additionalInformation: currentWidth,
          };
        },
      );
    }
  };

  const handleScrollRight = () => {
    const additionalInfoContainer = containerRef.current;
    if (additionalInfoContainer) {
      additionalInfoContainer.scrollLeft += additionalInfoContainer.clientWidth;
      if (
        additionalInfoContainer.scrollLeft + 1 >=
        additionalInfoContainer.scrollWidth -
          additionalInfoContainer.clientWidth
      ) {
        setSliderState((prevState) => ({
          ...prevState,
          endReached: true,
        }));
      } else {
        setSliderState((prevState) => ({
          ...prevState,
          endReached: false,
        }));
      }
      if (additionalInfoContainer.scrollLeft > 0) {
        setSliderState((prevState) => ({
          ...prevState,
          startReached: false,
        }));
      } else {
        setSliderState((prevState) => ({
          ...prevState,
          startReached: true,
        }));
      }
    }
  };

  const handleScrollLeft = () => {
    const additionalInfoContainer = containerRef.current;
    if (additionalInfoContainer) {
      additionalInfoContainer.scrollLeft -= additionalInfoContainer.clientWidth;
      if (additionalInfoContainer.scrollLeft < 1) {
        setSliderState((prevState) => ({
          ...prevState,
          startReached: true,
        }));
      }
      if (
        additionalInfoContainer.scrollWidth <=
        additionalInfoContainer.scrollWidth -
          additionalInfoContainer.clientWidth
      ) {
        setSliderState((prevState) => ({
          ...prevState,
          endReached: true,
        }));
      } else {
        setSliderState((prevState) => ({
          ...prevState,
          endReached: false,
        }));
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.topWrapper}>
        <h3 className={styles.name}>{workingGraduate.name}</h3>
      </div>
      <div className={styles.iconWrapper}>
        <div>
          <a
            href={workingGraduate.linkedinUrl}
            className={styles.personalInformation}
          >
            <Image
              className={styles.linkedinLogo}
              src={"/svg/linkedin.svg"}
              alt={"avatar image"}
              height={34}
              width={34}
            />
          </a>
        </div>
      </div>
      <div className={styles.bottomWrapper}>
        {(workingGraduate.education ||
          workingGraduate.professionalBackground) && (
          <div className={styles.extraInformationBanner}>
            <span
              className={classnames([styles.arrowLeft], {
                [styles.arrowLeftShadow]: !sliderState.startReached,
                [styles.arrowHidenLeft]: sliderState.startReached,
              })}
              onClick={handleScrollLeft}
            >
              <Image
                src={"/svg/arrow-slider.svg"}
                alt={"arrow image"}
                height={10}
                width={6}
              />
            </span>
            <div className={styles.bottomInfoWrapper} ref={containerRef}>
              {workingGraduate.professionalBackground && (
                <p className={styles.extraInformation}>
                  <Portfolio width={17} height={15} viewBox="0 0 34 34" />
                  <span>{workingGraduate.professionalBackground}</span>
                </p>
              )}
              {workingGraduate.education && (
                <p className={styles.extraInformation}>
                  <Education width={17} height={15} viewBox="0 0 64 64" />
                  <span>{workingGraduate.education}</span>
                </p>
              )}
            </div>
            <span
              className={classnames([styles.arrow], {
                [styles.arrowHidenTechSkills]: sliderState.endReached,
              })}
              onClick={handleScrollRight}
            >
              <Image
                src={"/svg/arrow-slider.svg"}
                alt={"arrow image"}
                height={10}
                width={6}
              />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
