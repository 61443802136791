import React, { FC } from "react";
import { Timeline } from "antd";
import { ApplicationBanner } from "@components/molecules/ApplicationBanner";
import styles from "./index.module.scss";

type Props = {
  onApply: () => void;
};

const { Item: TimelineItem } = Timeline;

const selectionProcess = [
  "Aprunāsimies par dzīvi un vēlmi programmēt.",
  "Atrādīsi veikto uzdevumu un atbildēsi uz pāris jautājumiem par rakstīto kodu - tas nepieciešams, lai novērtētu Tavu kopējo izpratni par padarīto un pārliecinātos, ka uzdevumus tik tiešām esi pildījis pats.",
];

export const SelectionProcessComponent: FC<Props> = ({ onApply }) => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Pieteikums intervijai</h1>
      <div className={styles.applicationBlock}>
        <ApplicationBanner onApply={onApply} />
      </div>

      <h3 className={styles.subTitle}>Atlases process</h3>
      <p className={styles.text}>
        Atlases process ir pavisam vienkāršs &#8211; īss zvans tiešsaistē, kura
        laikā:
      </p>
      <br />
      <div className={styles.timeLineWrapper}>
        <Timeline>
          {selectionProcess.map((it, i) => (
            <TimelineItem dot={<span>{i + 1}</span>} key={i}>
              <div className={styles.adminText}>{it}</div>
            </TimelineItem>
          ))}
        </Timeline>
      </div>

      <p className={styles.text}>
        Intervija notiks Google Meet vai Zoom platformā. Lai sagatavotos,
        pārbaudi, ka skaņa strādā labi. Tāpat vēlams, ka intervijā piedalies ar
        ieslēgtu kameru.
        <span className={styles.highlightText}>
          &#160;Intervijas laikā būs nepieciešams atrādīt kodu.
        </span>
      </p>
    </div>
  );
};
