import { TaskBanner } from "@components/atoms";
import { DescriptionGroup } from "@components/atoms/DescriptionGroup";
import { EmbeddedYouTubeVideo } from "@components/atoms/EmbeddedYouTubeVideo";
import { GithubLink } from "@components/atoms/GithubLink";
import { ZoomMeeting } from "@components/molecules/ZoomMeeting";
import { Lesson, LessonMdHtmlContent } from "@content/lessons/types";
import styles from "./index.module.scss";

export type Props = {
  startTime: number;
  videoLabel?: string;
  lesson: Lesson;
  onJoin: () => void;
  content?: LessonMdHtmlContent[];
  notificationComponent: React.ReactNode;
};

export interface LessonNotification {
  showNotification: boolean;
  onNotification: () => void;
}

export const Lessons = ({
  startTime,
  onJoin,
  videoLabel,
  lesson,
  content,
  notificationComponent,
}: Props) => {
  const filterById = (id: string) => {
    const filteredArr = content?.filter((item) => {
      return item.id === id;
    });
    return filteredArr ? filteredArr[0].html : "";
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWithDate}>
        <div className={styles.titleBlock}>
          <h1 className={styles.title}>Ievadkurss</h1>
          <DescriptionGroup />
          {notificationComponent}
          <h1 className={styles.lessonTitle}>{lesson.title}</h1>
          <ZoomMeeting startTime={startTime} onJoin={onJoin} />
        </div>
      </div>
      {lesson.infoText && (
        <p className={styles.lessonDesc}>{lesson.infoText}</p>
      )}
      {lesson.items &&
        lesson.items.map((items) => (
          <TaskBanner
            key={items.id}
            title={items.title}
            text={items.description}
            number={items.id}
          />
        ))}

      <div className={styles.githubWrapper}>
        <GithubLink
          desc="Visi uzdevumi ir pieejami Codelex Github koda glabātuvē."
          href="https://github.com/codelex-io/prep-course-warm-up"
          title="Github"
        />
      </div>
      <h3 className={styles.videoLabel}>{videoLabel}</h3>
      <div className={styles.videoBlock}>
        <EmbeddedYouTubeVideo src={lesson.videoUrl} />
      </div>
      <div
        className={styles.contentAfterVideo}
        dangerouslySetInnerHTML={{
          __html: filterById("links.md"),
        }}
      />
    </div>
  );
};
