import React, { useRef, useState } from "react";
import { AvailableForPayout } from "@components/atoms/AvailableForPayout";
import { HeaderLogo } from "@components/atoms/HeaderLogo";
import { NavMenu } from "@components/atoms/NavMenu";
import { BurgerMenu } from "@components/molecules/BurgerMenu";
import { useAccountContext } from "@containers/customer/AccountContext";
import styles from "./index.module.scss";

type MenuItem = {
  title: string;
  path: string;
};

export interface Props {
  username?: string;
  menuItems: MenuItem[];
  pathName: string;
  referrals?: {
    balance: number;
    onClick: () => void;
  };
}

export const Header = ({ username, menuItems, pathName, referrals }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const burgerMenuRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const { signOut } = useAccountContext();
  const onMenuOpen = () => setIsMenuOpen(!isMenuOpen);
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <HeaderLogo link="/" />

          <div className={styles.demo} onClick={onMenuOpen}>
            <div className={styles.menuIcon} data-testid="burger-menu-btn">
              <input
                className={styles.menuIconCheckbox}
                type="checkbox"
                checked={isMenuOpen}
                readOnly
              />
              <div className={styles.spanWrapper}>
                <span className={styles.span} />
                <span className={styles.span} />
              </div>
            </div>
          </div>
          <div>
            <div>
              <NavMenu pathName={pathName} menuItems={menuItems} />
            </div>
          </div>
        </div>
        {referrals && (
          <div className={styles.accumulatedMoneyWrapper}>
            <AvailableForPayout
              accumulatedMoney={referrals.balance}
              onMoneyClick={referrals.onClick}
            />
          </div>
        )}
      </div>

      <div ref={burgerMenuRef} className={styles.burgerMenuWrap}>
        <BurgerMenu
          burgerRef={burgerMenuRef}
          open={isMenuOpen}
          items={menuItems}
          handleMenu={onMenuOpen}
          onSignOut={() => signOut()}
          userName={username}
        />
      </div>
    </>
  );
};
