import React, { FC } from "react";
import { Divider } from "antd";
import Image from "next/legacy/image";
import { LinkedinShareButton } from "next-share";
import { Button } from "@components/atoms/Button";
import { HeaderLogo } from "@components/atoms/HeaderLogo";
import { Modal } from "@components/atoms/Modal";
import HighFiveIcon from "@public/svg/high-five.svg";
import CodelexLogo from "@public/svg/codelex-logo.svg";
import styles from "./index.module.scss";

export type Props = {
  onClose: () => void;
  shareLink: string;
};

export const HighFive: FC<Props> = ({ onClose, shareLink }) => {
  return (
    <Modal width="600px" visible onCancel={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.close} onClick={onClose}>
          <Image
            src={"/svg/close.svg"}
            alt={"close image"}
            height={18}
            width={18}
          />
        </div>
        <div className={styles.highFiveWrapper}>
          <HighFiveIcon className={styles.highFiveIcon} />
        </div>

        <div className={styles.cardWrapper}>
          <div className={styles.titleWrapper}>
            <span className={styles.cardTitle} data-testid="high-five">
              High Five
            </span>
          </div>

          <div className={styles.descWrapper}>
            <span className={styles.cardDesc}>
              Sveicam Tevi ar<br></br> jaunās darba vietas iegūšanu!
            </span>
          </div>

          <Divider className={styles.divider} />
          <div className={styles.titleWrapper}>
            <span className={styles.subTitle}>
              Nākamais solis ir publicēt šo informāciju savā LinkedIn profilā.
            </span>
          </div>

          <div className={styles.shareBanner}>
            <CodelexLogo className={styles.codelexLogo} />
            <HighFiveIcon className={styles.bannerHighFiveIcon} />
            <div className={styles.bannerDescWrapper}>
              <span className={styles.bannerDesc}>
                Es tikko saņēmu jaunu darba piedāvājumu
              </span>
              <div className={styles.thankYouText} onClick={onClose}>
                Paldies
              </div>
            </div>

            <div className={styles.logoWrapper}>
              <HeaderLogo link="/" />
            </div>
          </div>
          <div className={styles.shareWrapper}>
            <LinkedinShareButton
              style={{ width: "100%" }}
              blankTarget={true}
              url={shareLink}
            >
              <Button>Dalīties LinkedIn</Button>
            </LinkedinShareButton>
          </div>

          <div className={styles.closeTextWrapper}>
            <span
              className={styles.closeText}
              onClick={onClose}
              data-testid="high-five-do-not-share"
            >
              Šoreiz nē, paldies
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};
