import { Tabs } from "antd";
import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./index.module.scss";

const { TabPane } = Tabs;

export type TabType = {
  id: string;
  label: string | ReactNode;
  disabled?: boolean;
  renderContent: () => ReactNode;
};

export type Props = {
  activeTab?: string;
  tabs: TabType[];
  position: "right" | "bottom";
  onChange?: (activeKey: string) => void;
  extraContent?: React.ReactNode;
};

export const TabPanel = ({
  activeTab,
  tabs,
  position,
  onChange,
  extraContent,
}: Props) => {
  return (
    <div
      className={classNames(styles.rootWrapper, {
        [styles.secondaryType]: position === "bottom",
        [styles.showVideoCourseBanner]: true,
      })}
    >
      <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        tabPosition={position}
        onTabClick={onChange}
        tabBarExtraContent={
          extraContent && (
            <div className={styles.extraContentWrapper}>{extraContent}</div>
          )
        }
      >
        {tabs.map((it) => {
          return (
            <TabPane key={it.id} tab={it.label} disabled={it.disabled}>
              {it.renderContent && it.renderContent()}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};
