import classNames from "classnames";
import { HomeAssignmentType } from "@content/homework/types";
import { CalendarSchedule } from "@components/atoms/CalendarSchedule";
import { IntroCourseLesson } from "@domain/intro-course/types";
import { Account, CodingEvent } from "@components/layouts/types";
import { NextLessonTimer } from "@components/molecules/NextLessonTimer";
import { useJoinCodingEvent } from "@containers/customer/JoinCodingEventContext";
import { SheCanDoITBanner } from "@components/atoms/SheCanDoITBanner";
import {
  GameBanner,
  HomeAssignmentBanner,
  SuccessfullySubmittedAssignmentBanner,
} from "@components/atoms";
import styles from "./index.module.scss";

export interface Props {
  tabs: {
    key: HomeAssignmentType;
    title: string;
    button: {
      title: string;
      titleToContinue: string;
      href: string;
      disabled?: boolean;
    };
    url?: {
      prefix: string;
      placeholder: string;
    };
    desc: string;
  }[];
  lessons: IntroCourseLesson[];
  now: number;
  event: CodingEvent | undefined;
  trackAssignmentClick: (key: string) => void;
  waitingTime: number;
  quizState?: string;
  account: Account;
}

export const HomeAssignmentComponent = ({
  tabs,
  lessons,
  now,
  event,
  trackAssignmentClick,
  waitingTime,
  quizState,
  account,
}: Props) => {
  const { showJoinCodingEventModal } = useJoinCodingEvent();
  return (
    <div
      className={classNames(styles.container, {
        [styles.adjustedContainer]: tabs.length < 1,
      })}
    >
      <div>
        {!account.assignmentCompleted && (
          <h1 className={styles.title}>Programmēšanas kursi</h1>
        )}
        <div
          className={classNames(styles.wrapper, {
            [styles.adjustedWrapper]: account.assignmentCompleted,
            [styles.adjustedWrapperMobile]: tabs.length < 1,
          })}
        >
          {account.assignmentCompleted ? (
            <SuccessfullySubmittedAssignmentBanner />
          ) : (
            <p className={styles.text}>
              Priecājamies Tevi redzēt Codelex programmēšanas skolā! Te apgūsi
              Java, C#, PHP un Web izstrādi, kā arī iegūsi visas darba tirgū
              nepieciešamās prasmes. Lai sākas Tavs ceļš uz programmētāja
              profesiju un labi atalgotu darbu IT jomā!
            </p>
          )}
          <div
            className={classNames(styles.nextLessonTimerWrapper, {
              [styles.adjustedNextLessonTimerWrapper]:
                account.assignmentCompleted,
            })}
          >
            {!account.hideContent && (
              <NextLessonTimer
                now={now}
                startTime={event?.startTime ? event.startTime : now}
                onJoin={event?.available ? showJoinCodingEventModal : undefined}
              />
            )}
          </div>
          {!account.assignmentCompleted && (
            <h2 className={styles.subTitle}>Pārbaudes mājasdarbs</h2>
          )}
        </div>
        <div
          className={classNames(styles.homeAssignmentWrapper, {
            [styles.oneHomeAssignmentWrapper]: !account.assignmentCompleted,
          })}
        >
          {tabs.map((items, index) => {
            return (
              <HomeAssignmentBanner
                key={index}
                text={
                  <>
                    <div
                      className={styles.contentParagraph}
                      dangerouslySetInnerHTML={{ __html: items.desc }}
                    ></div>
                  </>
                }
                title={items.title}
                number={account.assignmentCompleted ? index + 2 : index + 1}
                quizState={quizState}
                waitingTime={waitingTime}
                type={items.key}
                button={{
                  title: items.button.title,
                  titleToContinue: items.button.titleToContinue,
                  href: items.button.href,
                  disabled: items.button.disabled,
                  trackClick: () => trackAssignmentClick(items.key),
                }}
              />
            );
          })}
        </div>
      </div>
      {!account.hideContent && (
        <div className={styles.calendarScheduleWrapper}>
          <CalendarSchedule calendar={lessons} />
        </div>
      )}
      {!account.assignmentTypes?.includes("COGNITIVE_TEST") &&
        !account.assignmentCompleted && (
          <div className={styles.gameBannerWrapper}>
            <GameBanner
              trackClick={() => trackAssignmentClick("COGNITIVE_TEST")}
            />
          </div>
        )}
      <div className={classNames(styles.banner, {})}>
        {account.gender === "FEMALE" && !account.isRegisteredToSheCanDoIt && (
          <SheCanDoITBanner accountId={account.id} />
        )}
      </div>
    </div>
  );
};
