import Image from "next/legacy/image";
import { Button } from "@components/atoms/Button";
import { CountDown } from "@components/atoms/CountDown";
import styles from "./index.module.scss";

export type Props = {
  onExpired: () => void;
  button: {
    title: string;
    onClick: () => void;
  };
  wait: number;
};

export const FailedQuizView = ({ onExpired, button, wait }: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.leftSideWrapper}>
        <h1 className={styles.title}>Diemžēl tests netika nokārtots.</h1>
        <p className={styles.description}>
          Lai iegūtu piekļuvi ievadkursa sadaļai, tests ir jānokārto veiksmīgi.
        </p>
        <p className={styles.description}>
          Lai sagatavotos testam un uzlabotu zināšanas, esam sagatavojuši mācību
          materiālus. Dodies jau tagad un apgūsti zināšanas.
        </p>
        <Button type="default" onClick={button.onClick}>
          {button.title}
        </Button>
        <p className={styles.highlight}>
          Testu varēs atkārtot pēc{" "}
          <CountDown
            onExpired={onExpired}
            timeFormat={"mm:ss"}
            intervalMs={1000}
            initialDuration={wait}
          />
        </p>
      </div>
      <div className={styles.rightSideWrapper}>
        <Image
          src={"/svg/sad.svg"}
          alt="sad-face icon"
          height={353}
          width={353}
        />
      </div>
    </div>
  );
};
