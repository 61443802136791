import { useEffect, useState, useRef } from "react";
import Image from "next/legacy/image";
import classnames from "classnames";
import { JobSeeker } from "@domain/job-seekers/types";
import Location from "@public/svg/location.svg";
import Portfolio from "@public/svg/portfolio-small.svg";
import Education from "@public/svg/light-bulb.svg";
import styles from "./index.module.scss";

type Props = {
  jobSeeker: JobSeeker;
  showDescription?: boolean;
};

export const JobSeekerCard = ({ jobSeeker, showDescription }: Props) => {
  const {
    name,
    path,
    contactDetails,
    technicalSkills,
    jobSeekerDescription,
    profileImgUrl,
    linkedinUrl,
    githubUrl,
    education,
    professionalBackground,
    willingToRelocate,
  } = jobSeeker;

  let trimmedJobSeekerDesc = "";
  const containersRefs = {
    containerRef: useRef<HTMLDivElement>(null),
    techSkillContainerRef: useRef<HTMLDivElement>(null),
  };
  const [sliderState, setSliderState] = useState({
    endReached: {
      additionalInformation: false,
      technicalSkills: false,
    },
    startReached: {
      additionalInformation: true,
      technicalSkills: true,
    },
  });
  const [showAllJobSeekerDesc, setShowAllJobSeekerDesc] = useState(false);
  const [hasError, setHasError] = useState(false);

  const toggleShowAll = () => {
    setShowAllJobSeekerDesc(!showAllJobSeekerDesc);
  };

  const [, setPrevContainerWidth] = useState({
    technicalSkills: containersRefs.techSkillContainerRef.current?.clientWidth,
    additionalInformation: containersRefs.containerRef.current?.clientWidth,
  });
  useEffect(() => {
    const { techSkillContainerRef, containerRef } = containersRefs;
    const techSkillContainer = techSkillContainerRef.current;
    const additionalInfoContainer = containerRef.current;
    if (additionalInfoContainer) {
      setSliderState((prevState) => ({
        ...prevState,
        endReached: {
          ...prevState.endReached,
          additionalInformation:
            additionalInfoContainer.scrollWidth <=
            additionalInfoContainer.clientWidth,
        },
      }));
    }
    if (techSkillContainer) {
      setSliderState((prevState) => ({
        ...prevState,
        endReached: {
          ...prevState.endReached,
          technicalSkills:
            techSkillContainer.scrollWidth <= techSkillContainer.clientWidth,
        },
      }));
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const { techSkillContainerRef, containerRef } = containersRefs;
    const techSkillContainer = techSkillContainerRef.current;
    const additionalInfoContainer = containerRef.current;
    const windowWidth = window.innerWidth;
    if (windowWidth < 470) {
      if (techSkillContainer) {
        handleContainerResize(techSkillContainer, "technicalSkills");
      }
      if (additionalInfoContainer) {
        handleContainerResize(additionalInfoContainer, "additionalInformation");
      }
    } else {
      if (additionalInfoContainer) {
        if (
          additionalInfoContainer.scrollWidth <=
          additionalInfoContainer.clientWidth
        ) {
          setSliderState((prevState) => ({
            ...prevState,
            endReached: {
              ...prevState.endReached,
              additionalInformation: true,
            },
          }));
        }
        if (
          additionalInfoContainer.scrollWidth >
            additionalInfoContainer.clientWidth &&
          additionalInfoContainer.scrollLeft < 1
        ) {
          setSliderState((prevState) => ({
            ...prevState,
            endReached: {
              ...prevState.endReached,
              additionalInformation: false,
            },
          }));
        }
      }
      if (techSkillContainer) {
        if (techSkillContainer.scrollWidth <= techSkillContainer.clientWidth) {
          setSliderState((prevState) => ({
            ...prevState,
            endReached: {
              ...prevState.endReached,
              technicalSkills: true,
            },
          }));
        }
        if (
          techSkillContainer.scrollWidth > techSkillContainer.clientWidth &&
          techSkillContainer.scrollLeft < 1
        ) {
          setSliderState((prevState) => ({
            ...prevState,
            endReached: {
              ...prevState.endReached,
              technicalSkills: false,
            },
          }));
        }
      }
    }

    const currentAdditionalInfoWidth = additionalInfoContainer?.clientWidth;
    const currentTechSkillWidth = techSkillContainer?.clientWidth;

    setPrevContainerWidth((prevWidth) => ({
      ...prevWidth,
      additionalInformation: currentAdditionalInfoWidth,
      technicalSkills: currentTechSkillWidth,
    }));
  };

  const handleContainerResize = (
    container: HTMLDivElement,
    key: "additionalInformation" | "technicalSkills",
  ) => {
    const currentWidth = container.clientWidth;
    setPrevContainerWidth((prevWidth) => {
      if (prevWidth !== undefined) {
        if (currentWidth > prevWidth[key]!) {
          if (container.scrollWidth > container.clientWidth) {
            updateSliderState(key, "endReached", false);
          } else if (sliderState.endReached[key]) {
            updateSliderState(key, "endReached", true);
          }
          if (container.scrollLeft > 0) {
            updateSliderState(key, "startReached", false);
          } else {
            updateSliderState(key, "startReached", true);
          }
        } else if (currentWidth < prevWidth[key]!) {
          if (container.scrollLeft < 1) {
            updateSliderState(key, "startReached", true);
          } else {
            updateSliderState(key, "startReached", false);
          }
          if (container.scrollWidth > container.clientWidth) {
            updateSliderState(key, "endReached", false);
          } else {
            updateSliderState(key, "endReached", true);
          }
        }
      }
      return {
        ...prevWidth,
        [key]: currentWidth,
      };
    });
  };

  const updateSliderState = (
    key: "additionalInformation" | "technicalSkills",
    property: "endReached" | "startReached",
    value: boolean,
  ) => {
    setSliderState((prevState) => ({
      ...prevState,
      [property]: {
        ...prevState[property],
        [key]: value,
      },
    }));
  };
  const handleScroll = (container: number, side: string) => {
    const { techSkillContainerRef, containerRef } = containersRefs;
    const techSkillContainer = techSkillContainerRef.current;
    const additionalInfoContainer = containerRef.current;
    const scrollContainer =
      container === 1 ? additionalInfoContainer : techSkillContainer;
    const containerName =
      container === 1 ? "additionalInformation" : "technicalSkills";
    if (scrollContainer) {
      if (side === "left") {
        scrollContainer.scrollLeft -= scrollContainer.clientWidth;
        if (scrollContainer.scrollLeft < 1) {
          updateSliderState(containerName, "startReached", true);
        }
        if (
          scrollContainer.scrollWidth <=
          scrollContainer.scrollWidth - scrollContainer.clientWidth
        ) {
          updateSliderState(containerName, "endReached", true);
        } else {
          updateSliderState(containerName, "endReached", false);
        }
      } else if (side === "right") {
        scrollContainer.scrollLeft += scrollContainer.clientWidth;
        if (
          scrollContainer.scrollLeft + 1 >=
          scrollContainer.scrollWidth - scrollContainer.clientWidth
        ) {
          updateSliderState(containerName, "endReached", true);
        }
        if (scrollContainer.scrollLeft > 0) {
          updateSliderState(containerName, "startReached", false);
        } else {
          updateSliderState(containerName, "startReached", true);
        }
      }
    }
  };
  if (jobSeekerDescription) {
    trimmedJobSeekerDesc =
      jobSeekerDescription.length > 182
        ? jobSeekerDescription.substring(0, 182) + "..."
        : jobSeekerDescription;
  }
  return (
    <div className={styles.container}>
      <div className={styles.topWrapper}>
        <div
          className={classnames([styles.studentCredidentals], {
            [styles.studentCredidentalsWithPhoto]: !hasError && profileImgUrl,
          })}
        >
          {!hasError && profileImgUrl && (
            <div>
              <Image
                src={profileImgUrl}
                alt={"user logo"}
                width={83}
                height={83}
                className={styles.profileImage}
                onError={() => {
                  setHasError(true);
                }}
              />
            </div>
          )}
          <div>
            <h3 className={styles.name}>{name}</h3>
            <h4 className={styles.position}>{path} Developer</h4>
          </div>
        </div>
        <div className={styles.personalInformationWrapper}>
          <div className={styles.contactInfoWrapper}>
            {contactDetails && (
              <>
                <div>
                  <Image
                    src={"/svg/phone.svg"}
                    alt={"phone image"}
                    height={12}
                    width={12}
                  />
                  <span className={styles.personalInformation}>
                    {contactDetails.phoneNumber.substring(0, 4) +
                      " " +
                      contactDetails.phoneNumber.substring(4)}
                  </span>
                </div>
                <div>
                  <Image
                    className={styles.mailLogo}
                    src={"/svg/mail.svg"}
                    alt={"mail image"}
                    height={12}
                    width={12}
                  />
                  <span className={styles.personalInformation}>
                    {contactDetails.email}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className={styles.iconWrapper}>
            <div>
              <a
                href={linkedinUrl}
                className={styles.personalInformation}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  className={styles.linkedinLogo}
                  src={"/svg/linkedin.svg"}
                  alt={"linkedin image"}
                  height={34}
                  width={34}
                />
              </a>
            </div>
            {githubUrl ? (
              <div>
                <a
                  href={githubUrl}
                  className={styles.personalInformation}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className={styles.linkedinLogo}
                    src={"/svg/github.svg"}
                    alt={"github image"}
                    height={34}
                    width={34}
                  />
                </a>
              </div>
            ) : null}
          </div>
        </div>
        {showDescription && jobSeekerDescription && (
          <div
            className={classnames([styles.description], {
              [styles.descriptionWithShadow]:
                !showAllJobSeekerDesc && jobSeekerDescription.length > 182,
            })}
          >
            <div className={styles.shadowEffect}></div>
            <div
              className={classnames([styles.textContainer], {
                [styles.textContainerFull]: showAllJobSeekerDesc,
                [styles.textContainerForShortDesc]:
                  jobSeekerDescription.length < 183,
              })}
            >
              {showAllJobSeekerDesc
                ? jobSeekerDescription
                : trimmedJobSeekerDesc}
            </div>
            {jobSeekerDescription.length > 182 && (
              <div className={styles.showAllText} onClick={toggleShowAll}>
                <Image
                  src={"/svg/arrow-slider.svg"}
                  alt={"arrow"}
                  height={10}
                  width={10}
                  style={{
                    transform: showAllJobSeekerDesc
                      ? "rotate(-90deg)"
                      : "rotate(90deg)",
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.bottomWrapper}>
        {(education || professionalBackground || willingToRelocate) && (
          <div className={styles.extraInformationBanner}>
            <span
              className={classnames([styles.arrowLeft], {
                [styles.arrowLeftShadow]:
                  !sliderState.startReached.additionalInformation,
                [styles.arrowHidenLeft]:
                  sliderState.startReached.additionalInformation,
              })}
              onClick={() => handleScroll(1, "left")}
            >
              <Image
                src={"/svg/arrow-slider.svg"}
                alt={"arrow image"}
                height={10}
                width={6}
              />
            </span>
            <div
              className={styles.bottomInfoWrapper}
              ref={containersRefs.containerRef}
            >
              {willingToRelocate && (
                <p className={styles.extraInformation}>
                  <Location />
                  <span>Available for relocation in EU</span>
                </p>
              )}
              {professionalBackground && (
                <p className={styles.extraInformation}>
                  <Portfolio width={17} height={15} viewBox="0 0 34 34" />
                  <span>{professionalBackground}</span>
                </p>
              )}
              {education && (
                <p className={styles.extraInformation}>
                  <Education width={17} height={15} viewBox="0 0 64 64" />
                  <span>{education}</span>
                </p>
              )}
            </div>
            <span
              className={classnames([styles.arrow], {
                [styles.arrowHiden]:
                  sliderState.endReached.additionalInformation,
              })}
              onClick={() => handleScroll(1, "right")}
            >
              <Image
                src={"/svg/arrow-slider.svg"}
                alt={"arrow image"}
                height={10}
                width={6}
              />
            </span>
          </div>
        )}
        <div className={styles.scrollwrapper}>
          <span
            className={classnames([styles.arrowLeft], {
              [styles.arrowTechSkillsLeftShadow]:
                !sliderState.startReached.technicalSkills,
              [styles.arrowTechSkillsHidenLeft]:
                sliderState.startReached.technicalSkills,
            })}
            onClick={() => handleScroll(2, "left")}
          >
            <Image
              src={"/svg/arrow-slider.svg"}
              alt={"arrow image"}
              height={10}
              width={6}
              className={styles.arrowCheck}
            />
          </span>

          <div
            className={styles.bottomInfoWrapper}
            ref={containersRefs.techSkillContainerRef}
          >
            {technicalSkills.map((skill, index) => (
              <span key={index} className={styles.technicalSkillsWrapper}>
                {skill}
              </span>
            ))}
          </div>

          <span
            className={classnames([styles.arrow], {
              [styles.arrowHidenTechSkills]:
                sliderState.endReached.technicalSkills,
            })}
            onClick={() => handleScroll(2, "right")}
          >
            <Image
              src={"/svg/arrow-slider.svg"}
              alt={"arrow image"}
              height={10}
              width={6}
            />
          </span>
        </div>
      </div>
    </div>
  );
};
