import { format as _format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const formatUTCToLocal = ({
  date,
  format,
}: {
  date: Date | string | number;
  format: string;
}) => {
  return _format(
    utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone),
    format,
  );
};

export const asUTCDate = (date: Date) => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
};
