import { Form, Select } from "antd";
import { countries } from "@constants/countryCodes";

const { Option } = Select;

const sortedCountries = [...countries].sort(function (a, b) {
  if (a.phone == b.phone) {
    return 0;
  }
  if (a.phone == 371) {
    return -1;
  }
  if (b.phone == 371) {
    return 1;
  }

  if (a.phone < b.phone) {
    return -1;
  }
  if (a.phone > b.phone) {
    return 1;
  }
  return 0;
});

export const NextPhonePrefixSelector = () => {
  return (
    <Form.Item name="phoneNumberPrefix" noStyle>
      <Select
        defaultActiveFirstOption={true}
        style={{
          width: 85,
          height: 51,
          color: "rgba(255, 255, 255, 0.7)",
        }}
      >
        {sortedCountries.map((item) => (
          <Option
            key={item.name}
            style={{
              color: "rgba(255, 255, 255, 0.7)",
              background: "rgb(41, 47, 55)",
              fontFamily: "var(--visby)",
              fontSize: "16px",
            }}
            value={item.phone.toString()}
          >
            +{item.phone}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
