import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { ReferralStatistics } from "@domain/referrals/types";
import { formatDateToUtcNative } from "@utils/date-time/common";
import styles from "./index.module.scss";

export type Props = {
  stats: Omit<ReferralStatistics, "__typename">;
};

export const ReferralStatisticsTable = ({ stats }: Props) => {
  return (
    <>
      <div className={styles.headWrapper}>
        <div className={styles.eventWrapper}>
          <h1 className={styles.eventDate}>
            Datums <br />& Laiks
          </h1>
        </div>
        <div className={styles.screeningMeetingBooked}>
          <h3 className={styles.meetingBooked}>
            Tikšanās <br />
            ieplānota
          </h3>
        </div>
        <div className={styles.companyName}>
          <h3 className={styles.companyName}>
            Uzņēmuma
            <br /> nosaukums
          </h3>
        </div>
      </div>
      <div className={styles.mobile}>
        {stats.entries.map(
          ({ date, meetingBooked, company, ipAddress, userAgent, id }) => {
            return (
              <div className={styles.contentWrapper} key={id}>
                <div className={styles.eventWrapper}>
                  <div className={styles.headMobile}>Datums & Laiks</div>
                  <div className={styles.tooltip}>
                    <Tooltip
                      title={
                        <>
                          <span>IP: {ipAddress}</span>
                          <br />
                          <span>User- : {userAgent}</span>
                        </>
                      }
                    >
                      <InfoCircleOutlined className={styles.infoIcon} />
                    </Tooltip>

                    <h1
                      className={styles.eventDate}
                      style={{ cursor: "pointer" }}
                      suppressHydrationWarning
                    >
                      {formatDateToUtcNative(date)}
                    </h1>
                  </div>
                </div>

                <div className={styles.screeningMeetingBooked}>
                  <div className={styles.headMobile}>Tikšanās ieplānota</div>
                  <h3 className={styles.meetingBooked}>
                    {meetingBooked ? (
                      <span style={{ color: "green" }}>Jā</span>
                    ) : (
                      <span style={{ color: "#FF1111" }}>Nē</span>
                    )}
                  </h3>
                </div>
                <div className={styles.companyNameWrapper}>
                  <div className={styles.headMobile}>Uzņēmuma nosaukums</div>
                  <h3 className={styles.companyName}>{company ?? "-"}</h3>
                </div>
              </div>
            );
          },
        )}
      </div>
    </>
  );
};
