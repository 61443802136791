import dynamic from "next/dynamic";
import { ReactPlayerProps } from "react-player/types/lib";

const _ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

interface Props {
  videoUrl: string;
}

export const VimeoPlayer = ({ videoUrl }: Props) => {
  return <ReactPlayer width={"100%"} height={"100%"} controls url={videoUrl} />;
};
