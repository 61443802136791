import { z } from "zod";
import { User } from "next-auth";
import { JWT } from "next-auth/jwt";
import { WEB_JWT_TYPE } from "./constants";

const AUTH_CONNECTIONS_TYPES = [
  "google-oauth2",
  "facebook",
  "auth0",
  "github",
] as const;

export type AuthConnectionType = (typeof AUTH_CONNECTIONS_TYPES)[number];

const NEXT_AUTH_PROVIDER_TYPES = ["facebook", "google", "github"] as const;

export type NextAuthProvider = (typeof NEXT_AUTH_PROVIDER_TYPES)[number];

export const parseAuthConnectionType = (
  source: string | undefined,
): AuthConnectionType | undefined => {
  if (!source) {
    return;
  }
  if (AUTH_CONNECTIONS_TYPES.includes(source as AuthConnectionType)) {
    return source as AuthConnectionType;
  }
  return undefined;
};

export const parseNextAuthProvider = (
  source: string | undefined,
): NextAuthProvider | undefined => {
  if (!source) {
    return;
  }
  if (NEXT_AUTH_PROVIDER_TYPES.includes(source as NextAuthProvider)) {
    return source as NextAuthProvider;
  }
  return undefined;
};

export const toNextAuthProviderType = ({
  connectionType,
}: {
  connectionType: AuthConnectionType;
}): NextAuthProvider | undefined => {
  if (connectionType === "facebook") {
    return "facebook";
  }
  if (connectionType === "google-oauth2") {
    return "google";
  }
  if (connectionType === "github") {
    return "github";
  }
};

export const AUTH_ERROR_CODES = ["invalid_token", "account_exists"] as const;

export type AuthErrorCode = (typeof AUTH_ERROR_CODES)[number];

export const parseAuthErrorCode = (
  source: string | undefined,
): AuthErrorCode | undefined => {
  if (!source) {
    return;
  }
  if (AUTH_ERROR_CODES.includes(source as AuthErrorCode)) {
    return source as AuthErrorCode;
  }
  return undefined;
};

export interface ExtendedUser extends User {
  method?: string;
}

export const AccountJWTSchema = z.object({
  accountId: z.string().refine(
    (val) => /^\d{4}-\d{4}$/.test(val),
    (val) => ({
      message: `Invalid accountId=${val},  accountId must follow xxxx-xxxx format`,
    }),
  ),
  type: z.enum([WEB_JWT_TYPE]),
});

export type ExtendedJWT = JWT & z.infer<typeof AccountJWTSchema>;

export enum MagicLinkEmailStatus {
  PROCESSING = "PROCESSING",
  DELIVERED = "DELIVERED",
  FAILED = "FAILED",
}
