import GithubIcon from "@public/svg/github-filled.svg";
import FacebookIcon from "@public/svg/facebook.svg";
import GmailIcon from "@public/svg/gmail.svg";
import MailIcon from "@public/svg/mail.svg";
import { toStatus } from "@domain/account/auth-connections";
import { AuthConnection } from "@domain/account/auth-connections/types";
import styles from "./index.module.scss";

type Props = { connection: AuthConnection };

export const ConnectionIcon = ({ connection }: Props) => {
  const allowed = toStatus({ connection }) === "ALLOWED";
  if (connection.name === "google") {
    return (
      <GmailIcon
        className={allowed ? styles.iconGeneric : styles.iconDisabled}
      />
    );
  }
  if (connection.name === "github") {
    return (
      <GithubIcon
        className={allowed ? styles.iconGeneric : styles.iconDisabled}
      />
    );
  }
  if (connection.name === "facebook") {
    return (
      <FacebookIcon
        className={allowed ? styles.iconFacebook : styles.iconFacebookDisalbed}
      />
    );
  }
  if (connection.name === "email") {
    return (
      <MailIcon
        className={allowed ? styles.iconGeneric : styles.iconDisabled}
      />
    );
  }
  return <></>;
};
