import { Terms } from "@codelex/web-content";
import { ReferralCopyLink } from "@components/atoms/ReferralCopyLink";
import { ReferralsStatisticsCards } from "@components/atoms/ReferralsStatisticsCards";
import { TermsAndConditions } from "@components/atoms/TermsAndConditions";
import { ReferralNavigation } from "@domain/referrals/csr";
import { ReferralStatistics } from "@domain/referrals/types";
import { copyToClipboard } from "@integration/clipboard";
import styles from "./index.module.scss";

export interface Props {
  stats: Omit<ReferralStatistics, "__typename">;
  navigate: ReferralNavigation;
  terms: Terms;
}

export const ReferralDashboardComponent = ({
  stats,
  navigate,
  terms,
}: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperTitle}>
        <h1 className={styles.title}>Kopsavilkums</h1>
        <h3 className={styles.subTitle}>
          Iepazīstini uzņēmumums ar Codelex un gūsti papildus ienākumus
        </h3>
      </div>
      <ReferralCopyLink
        copyToClipboard={(link) => copyToClipboard({ content: link })}
        link={stats.link}
      />
      <ReferralsStatisticsCards
        clicks={stats.clicks}
        balance={stats.balance}
        navigate={navigate}
      />
      <TermsAndConditions
        githubUrl={terms.githubUrl}
        date={terms.updated}
        html={terms.html}
      />
    </div>
  );
};
