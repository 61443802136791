import React, { FC, useState } from "react";
import classNames from "classnames";
import { DatePicker, Form, Input, Radio } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { JobOffer, JobOfferStatus } from "@domain/job-offers";
import { Button } from "@components/atoms/Button";
import CalendarIcon from "@public/svg/calendar.svg";
import Portfolio from "@public/svg/portfolio-small.svg";
import {
  defaultCalendarFormat,
  defaultCalendarPlaceholder,
} from "@constants/calendarFormat";
import { JobApplicationUpsert } from "@domain/account/job-applications/types";
import styles from "./index.module.scss";

export type AddJobOfferFormOutput = {
  companyName: string;
  companyEmail: string;
  phoneNumber: string;
  salary: string;
  status: JobOfferStatus;
  employmentStartDate: string;
};

export type Props = {
  onAddJobOffer: (values: AddJobOfferFormOutput) => void;
  loading: boolean;
  onClose: () => void;
  jobOffer?: JobOffer;
  formData?: JobApplicationUpsert;
};

export const styleConfig = {
  height: "55px",
  borderRadius: "10px",
  paddingLeft: "12px",
};

const styleDatePickerConfig = {
  ...styleConfig,
  marginTop: "14px",
};

export const AddJobOffer: FC<Props> = ({
  onAddJobOffer,
  onClose,
  loading,
  jobOffer,
  formData,
}) => {
  const [form] = Form.useForm();

  const [status, setTakeStatus] = useState(jobOffer?.status);

  const onClickYes = () => {
    setTakeStatus(JobOfferStatus.ACCEPTED);
  };
  const onClickThinkAbout = () => {
    setTakeStatus(JobOfferStatus.PENDING);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.close} onClick={onClose}>
        <CloseOutlined
          style={{
            fontSize: "15px",
            color: "#fff",
          }}
        />
      </div>
      <div className={styles.portfolioWrapper}>
        <Portfolio className={styles.portfolioIcon} />
      </div>
      <div className={styles.cardWrapper}>
        <div className={styles.descWrapper}>
          <span className={styles.cardDesc} data-testid="add-job-offer-title">
            Darba piedāvājums
          </span>
        </div>

        <Form
          form={form}
          initialValues={{
            companyName: jobOffer?.company
              ? jobOffer.company
              : formData?.companyName,
            companyEmail: jobOffer?.email,
            phoneNumber: jobOffer?.phoneNumber,
            salary: jobOffer?.salary,
            takeOffer: status,
          }}
          style={{ paddingTop: "15px" }}
          wrapperCol={{ span: 24 }}
          onFinish={onAddJobOffer}
          autoComplete="off"
        >
          <Form.Item
            name="companyName"
            rules={[
              {
                required: true,
                message: "Ievadi uzņēmuma nosaukumu!",
              },
            ]}
          >
            <Input
              placeholder="Uzņēmuma nosaukums"
              style={styleConfig}
              disabled={loading}
            />
          </Form.Item>

          <Form.Item
            name="companyEmail"
            rules={[
              {
                required: false,
                message: "Ievadi pareizu uzņēmuma kontakta e-pastu!",
                type: "email",
              },
            ]}
          >
            <Input
              placeholder="Uzņēmuma kontakta e-pasts"
              style={styleConfig}
              disabled={loading}
            />
          </Form.Item>

          <Form.Item
            name="phoneNumber"
            rules={[
              {
                required: false,
                pattern: /^[0-9]{6,14}$/,
                message: "Ievadi pereizu uzņēmuma kontakta numuru!",
              },
            ]}
          >
            <Input
              placeholder="Uzņēmuma kontakta numurs"
              style={styleConfig}
              disabled={loading}
            />
          </Form.Item>

          <Form.Item
            name="salary"
            rules={[
              {
                required: true,
                pattern: /^(0|[1-9][0-9]{0,7})(\.[0-9]{0,2})?$/,
                message: "Ievadi korektu atalgojumu!",
              },
            ]}
          >
            <Input
              autoComplete="off"
              style={styleConfig}
              placeholder="Net atalgojums €"
              disabled={loading}
            />
          </Form.Item>
          <span className={styles.offerLabel}>Pieņemu piedāvājumu</span>
          <Form.Item
            className={styles.takeOfferItem}
            style={{ color: "hsla(244, 31%, 20%, 1)" }}
            name="status"
            initialValue={status === JobOfferStatus.PENDING && "PENDING"}
            rules={[
              {
                required: true,
                message: "Atbildes iesniegšana ir obligāta!",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => setTakeStatus(e.target.value)}
              className={styles.radioGroups}
            >
              <div className={styles.takeOfferAnswerWrapper}>
                <Radio value={JobOfferStatus.ACCEPTED}>
                  <span
                    onClick={onClickYes}
                    className={classNames(styles.radioButton, {
                      [styles.buttonActive]: status === JobOfferStatus.ACCEPTED,
                    })}
                  >
                    Jā
                  </span>
                </Radio>

                <Radio value={JobOfferStatus.PENDING}>
                  <span
                    onClick={onClickThinkAbout}
                    className={classNames(styles.radioButton, {
                      [styles.buttonActive]: status === JobOfferStatus.PENDING,
                    })}
                  >
                    Padomāšu
                  </span>
                </Radio>
              </div>
            </Radio.Group>
          </Form.Item>

          {status === JobOfferStatus.ACCEPTED && (
            <>
              <span className={styles.startDateDesc}>
                Lūdzu norādi, kad sāksi strādāt
              </span>

              <Form.Item
                name="employmentStartDate"
                getValueFromEvent={(onChange) =>
                  onChange.format(defaultCalendarFormat)
                }
                valuePropName={"date"}
                rules={[
                  {
                    message: "Ievadi darba sākšanas datumu!",
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  style={styleDatePickerConfig}
                  allowClear={false}
                  suffixIcon={
                    <div className={styles.calendarIconWrapper}>
                      <CalendarIcon />
                    </div>
                  }
                  placeholder={defaultCalendarPlaceholder}
                  data-testid="enter-employment-start-date-on-accept"
                />
              </Form.Item>
            </>
          )}

          <Form.Item>
            <div className={styles.buttonWrapper}>
              <Button
                type="default"
                htmlType="submit"
                disabled={loading}
                data-testid="add-job-offer-btn"
              >
                {jobOffer ? "Rediģēt" : "Pievienot"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
