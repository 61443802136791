import MoneySign from "@public/svg/money-sign.svg";
import styles from "./index.module.scss";

export const ReferralsHeader = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.referralLogo}>
        <MoneySign />
      </div>
    </div>
  );
};
