import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import Link from "next/link";
import { UserProfile } from "src/components/atoms/UserProfile";
import { SignOutButton } from "src/components/molecules/SignOutButton";
import styles from "./index.module.scss";

export type Props = {
  items: { title: string; path: string }[];
  open: boolean;
  handleMenu: () => void;
  burgerRef: React.MutableRefObject<HTMLDivElement>;
  onSignOut: () => void;
  userName?: string;
};

export const BurgerMenu: React.FC<Props> = ({
  items,
  open,
  burgerRef,
  handleMenu,
  onSignOut,
  userName,
}) => {
  const [activeItemIndex, setActiveItem] = useState<number | null>(null);
  const handleItemClick = (index: number) => {
    setActiveItem(index === activeItemIndex ? null : index);
  };

  useEffect(() => {
    open ? disableBodyScroll(burgerRef.current) : clearAllBodyScrollLocks();
  }, [open, burgerRef]);

  return (
    <div className={classNames(styles.wrapper, { [styles.open]: open })}>
      {items.map(({ title, path }, index) => {
        const isActiveItem = activeItemIndex === index;
        return (
          <Link
            key={index}
            href={path}
            className={classNames(styles.menuItem, {
              [styles.active]: isActiveItem,
            })}
            onClick={() => {
              handleItemClick(index);
              handleMenu();
            }}
          >
            {title}
          </Link>
        );
      })}

      <div className={styles.signOut}>
        <SignOutButton onSignOut={onSignOut} />
      </div>
      <div className={styles.userProfile}>
        <UserProfile email={userName} />
      </div>
    </div>
  );
};
