import Image from "next/legacy/image";
import { CalendarOutlined } from "@ant-design/icons";
import { Button } from "@components/atoms/Button";
import { displayDuration } from "@utils/date-time/common";
import { InformativeBanner } from "@components/molecules";
import StampIcon from "@public/svg/stamp.svg";
import { Props } from "./types";
import { Pending } from "./Pending";
import styles from "./index.module.scss";

const Content = (props: Props) => {
  const { status, reapplyTime, interviewBookingUrl, onNavigate, interview } =
    props;
  if (status === "PENDING" || interview) {
    return <Pending {...props} />;
  }
  if (status === "APPROVED") {
    return (
      <div className={styles.approvedWrapper}>
        <div className={styles.leftPartApprove}>
          <div className={styles.textWrapper}>
            <label className={styles.label}>Pieteikums: </label>
            <h1 className={styles.statusApprove}>Apstiprināts</h1>
            <p className={styles.description}>
              Tavs pieteikums ir apstiprināts. Vari doties uz līguma sadaļu un
              gatavoties intensīvajam kursam.
            </p>
            <div className={styles.buttonWrapper}>
              <Button type="text" onClick={() => onNavigate("/agreement")}>
                Līgums
              </Button>
            </div>
          </div>
          <div className={styles.stampIconWrapper}>
            <StampIcon className={styles.stampIcon} />
          </div>
        </div>
        <div className={styles.infoBannerWrapper}>
          <InformativeBanner
            text={"Sagatavošanās materiāli intensīvajam kursam"}
            buttonText={" Intensīvais kurss"}
            onClick={() => onNavigate("/intensive-course")}
          />
        </div>
      </div>
    );
  }
  if (interviewBookingUrl || reapplyTime) {
    return (
      <div className={styles.rejectedWrapper}>
        <div className={styles.leftPartReject}>
          <label className={styles.label}>Pieteikums: </label>
          <h1 className={styles.statusAnother}>Atkārtota intervija</h1>
          <p className={styles.description}>
            Nesatraucies, pirmā intervija ir neveiksmīga vairāk cilvēkiem kā
            iedomājies. Lai maksimāli palielinātu Tavas iespējas uzsākt labi
            algotu darbu IT nozarē, veicam kārtīgu atlasi un liekam pasvīst jau
            uzņemšanas laikā.
          </p>
          <div className={styles.buttonWrapper}>
            <Button
              type="primary"
              onClick={() => {
                if (interviewBookingUrl) {
                  window.open(interviewBookingUrl, "_blank");
                }
              }}
              disabled={!interviewBookingUrl}
            >
              Rezervēt laiku
            </Button>
          </div>
        </div>
        <div className={styles.rightPartReject}>
          <div className={styles.iconWrapper}>
            {!interviewBookingUrl ? (
              <Image
                src={"/svg/hourglass.svg"}
                alt={"hourglass image"}
                height={61}
                width={41}
              />
            ) : (
              <CalendarOutlined
                style={{
                  fontSize: "60px",
                  color: "#00fca3",
                }}
              />
            )}
          </div>
          <p className={styles.descriptionReapply}>
            {reapplyTime && displayDuration(reapplyTime / 1000)}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.rejectedWrapper}>
      <div className={styles.leftPartReject}>
        <label className={styles.label}>Pieteikums: </label>
        <h1 className={styles.statusAnother}>Noraidīts</h1>
        <p className={styles.description}>
          Diemžēl neesam gatavi Tevī investēt laiku, dodot solījumu, ka kļūsi
          par labu IT speciālistu.
        </p>
      </div>
    </div>
  );
};

export const ApplicationStatusBanner = ({
  status,
  reapplyTime,
  interviewBookingUrl,
  onNavigate,
  content,
  interview,
  isConsiderable,
}: Props) => {
  return (
    <div className={styles.wrapper}>
      <Content
        content={content}
        status={status}
        reapplyTime={reapplyTime}
        interviewBookingUrl={interviewBookingUrl}
        onNavigate={onNavigate}
        interview={interview}
        isConsiderable={isConsiderable}
      />
    </div>
  );
};
