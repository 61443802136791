import { z } from "zod";

export enum Language {
  LATVIAN = "latvian",
  ENGLISH = "english",
}

export interface JwtAware {
  jwt: string;
}

export interface ErrorResponse<T> {
  code: T;
  message: string;
}

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  UNKNOWN = "UNKNOWN",
}

export enum UncertainAnswer {
  YES = "YES",
  NO = "NO",
  UNKNOWN = "UNKNOWN",
}

export type AccountIdAware = { accountId: string };

export type AgreementIdAware = { agreementId: string };

export const COURSE_PATHS = ["C#", "Frontend", "Java", "PHP"] as const;

export const CoursePathSchema = z.enum(COURSE_PATHS);

export type CoursePath = z.infer<typeof CoursePathSchema>;
